import { t } from "i18next";
import React, { useMemo } from "react";
import { LoadingIcon, SmallTicket } from "src/components";

export const Coupons = (props: any) => {
  const { from, coupons, loading = false } = props;

  const isLoading = useMemo(() => {
    return loading;
  }, [loading]);

  const hasCoupon = useMemo(() => {
    return coupons && coupons.length > 0;
  }, [coupons]);

  return (
    <div className="mt-1 bg-transparent p-5 overflow-hidden overflow-x-scroll scroll-px-5 snap-x ">
      <div className="">
        {isLoading && (
          <div className="flex justify-center pt-5">
            <LoadingIcon />
          </div>
        )}

        {!hasCoupon && !isLoading && (
          <div className="flex justify-center pt-5">
            <p>{t("noCouponFound")}</p>
          </div>
        )}

        {hasCoupon &&
          !isLoading &&
          coupons.map((item: any, i: number) => {
            return (
              <div key={i} className="relative pb-5 last:pb-0">
                <SmallTicket
                  id={item["_id"]}
                  couponId={item.coupon_id}
                  couponCode={item.coupon_code}
                  couponTitle={item["name"]}
                  couponTitleEn={item["name_en"]}
                  couponIcon={item["logo"]}
                  amount={item.amount}
                  atPoints={item.at_points}
                  expirationDate={item.expiry_date}
                  earliestAvailableDate={item.earliest_available_date}
                  availableOutlets={item.available_outlets}
                  tnc={item.tnc}
                  instructions={item.how_to_use}
                  couponSupplies={item.coupon_supplies}
                  from={from}
                  stocks={item.available_stocks}
                  redemption_type={item.redemption_type}
                  type={item.type || ""}
                  remaining={item.remaining}
                  description={item.description || ""}
                  orderId={item.order_id || ""} //KKday
                  partnerOrderId={item.partner_order_id || ""} //KKday
                  isUsed={item.is_used || false} ////KKday
                  totalPrice={item.total_price || ""} //KKday
                  startDate={item.s_date || ""} //KKday
                  endDate={item.e_date || ""} //KKday
                />
              </div>
            );
          })}
      </div>

      {/* Hide for now */}
      {/* <div className="blcok center">
        <button
          className="full py-3 text-xs"
          onClick={() => setOpenModal(true)}
        >
          {t("button.filter")}
        </button>
      </div> */}
    </div>
  );
};
