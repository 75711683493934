import { t } from "i18next";
import React from "react";
import { Paper } from "src/components";
import { ImgListCarousel } from "./img-list-carousel";

export const ProductDescription = (props: any) => {
  const { data = {}, couponIcon = "/assets/AT_logo.png" } = props;
  const { name, description = "", coupon_id }: any = data;

  return (
    <Paper>
      <h5>{t(`form.summary`)}</h5>
      <div className="mt-[10px]">
        {data && (
          <div className="flex flex-col overflow-hidden">
            {/* Product Image - alt to carosell imglist */}
            <ImgListCarousel data={data} logo={couponIcon} />
          </div>
        )}
        {/* Product Name */}
        <h5 className="mt-4 text-black text-[16px] break-words">{name}</h5>
        {/* Coupon Location */}
        <div className="mt-4 flex flex-1 justify-between items-center">
          <p>{t("ticket.hk")}</p>
          <p>{t("productNo", { prodNo: coupon_id })}</p>
        </div>
      </div>

      <p className="mt-[10px] text-black">{description}</p>
    </Paper>
  );
};
