import React from "react";
import moment from "moment";
import { t } from "i18next";
import { ExpamdElementsArrow, LoadingIcon } from "src/components";

const { Collapse } = require("antd");
const { Panel } = Collapse;

export const PackageOptions = (props: any) => {
  const { data, isLoading, selectedPackageIdx, onSelectCallback } = props;
  const { pkg = [] } = data;

  return (
    <ExpamdElementsArrow>
      {pkg.length > 0 &&
        pkg.map((product: any, i: number) => {
          return (
            <Panel showArrow={true} header={product.pkg_name} key={i}>
              <div className="pb-[15px]">
                <div className="flex justify-end w-full">
                  <div className="flex flex-col pr-3 items-end">
                    <p className={`font-bold text-[16px] text-black`}>
                      {t(`price`, { at_points: product.at_points })}
                      <span className="font-bold text-[11px] text-slate-400">
                        {t(`upTo`)}
                      </span>
                    </p>
                    {parseFloat(product.face_value) <
                      parseFloat(product.at_points) && (
                      <p className={`line-through text-slate-400`}>
                        {product.face_value} AT
                      </p>
                    )}
                  </div>
                  <div>
                    <button
                      onClick={() => onSelectCallback(product, i)}
                      className={`${
                        selectedPackageIdx === i ? `secondary` : ``
                      } full text-xs py-1 cursor-pointer`}
                    >
                      {selectedPackageIdx === i && isLoading ? (
                        <LoadingIcon size={20} color={"#EA437A"} />
                      ) : selectedPackageIdx === i ? (
                        t("button.selected")
                      ) : (
                        t("button.select")
                      )}
                    </button>
                  </div>
                </div>

                {/* Earliest Available Date */}
                {selectedPackageIdx === i && !isLoading && (
                  <p className="text-right text-at-pink mt-[5px]">
                    {t("earliestAvailableDate")}
                    {moment(product.start_date).format("MM-DD-YYYY")}
                  </p>
                )}
              </div>
            </Panel>
          );
        })}
    </ExpamdElementsArrow>
  );
};
