import { t } from "i18next";
import React from "react";
import { ExpamdElementsArrow, Paper } from "src/components";
import { FormTextInput } from "./form-text-input";
import { FormSelectDropdown } from "./form-select-dropdown";
import { ShoeDropdownSelect } from "./shoe-dropdown-select";
import { formatSpecRefTxt } from "src/utils";

const { Collapse } = require("antd");
export const Cus02Form = (props: any) => {
  const { Panel } = Collapse;
  const {
    sku,
    personCount,
    cus02Index,
    cus02List,
    setCus02List,
    bookingField,
  } = props;

  const handleFormChange = (e: any, type?: string, selectType?: any) => {
    let name = null;
    let value = null;
    if (type === "select") {
      name = selectType;
      value = e;
    }

    if (type !== "select") {
      name = e.target.name;
      value = e.target.value;
    }

    let newCus02Obj = {
      ...cus02List[cus02Index],
      cus_type: "cus_02",
      [name]: value,
    };
    let newCus02List = [];
    newCus02List = cus02List;
    newCus02List[cus02Index] = newCus02Obj;
    setCus02List(newCus02List);
  };

  const { spec_rule, specs_ref = [] } = sku;
  const { max_age = "", min_age = "" } = spec_rule;

  return (
    <div className="mt-[20px] traveller-form">
      {bookingField && (
        <Paper>
          <h5>
            {t(`form.travellerInfo`)} {`(${personCount}) `}
          </h5>
          <ExpamdElementsArrow>
            <Panel showArrow={true} header={t("form.expand")} key={0}>
              {specs_ref.length > 0 &&
                specs_ref.map((ref: any, i: number) => (
                  <h5 key={i}>
                    {t(formatSpecRefTxt(ref.spec_value_id))}
                    <span className="ml-2">
                      {min_age &&
                        max_age &&
                        t("ages", { minAge: min_age, maxAge: max_age })}
                    </span>
                  </h5>
                ))}
              {/* English First Name */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="english_first_name"
              />

              {/* English Lsdt Name */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="english_last_name"
              />

              {/* Native First Name */}

              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="native_first_name"
              />

              {/* Native Last Name */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="native_last_name"
              />

              {/* Gender */}
              <FormSelectDropdown
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="gender"
              />

              {/* Guide Lang */}
              {/* <FormSelectDropdown
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="guide_lang"
              /> */}

              {/* Mainland travel permit */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="MTP_no"
              />

              {/* Passport No */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="passport_no"
              />

              {/* ID Number */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="id_no"
              />

              {/* Passpor Expired Date */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="passport_expdate"
              />

              {/* Birth */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="birth"
              />

              {/* Hight */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="height"
              />

              {/* Height Uunit */}
              <FormSelectDropdown
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="height_unit"
              />

              {/* Weight */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="weight"
              />

              {/* Weight Unit */}

              <FormSelectDropdown
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="weight_unit"
              />

              {/* Shoe Type + Shoe Options */}
              <div className="flex flex-row items-end">
                <FormSelectDropdown
                  bookingField={bookingField}
                  handleFormChange={handleFormChange}
                  customStyle="w-full"
                  name="shoe_type"
                />
                <ShoeDropdownSelect
                  bookingField={bookingField}
                  handleFormChange={handleFormChange}
                  name="shoe"
                />
              </div>

              {/* Shoe Unit */}
              <FormSelectDropdown
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="shoe_unit"
              />

              {/* Glass Degree - Interval 100, 100 - 600 */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="glass_degree"
              />

              {/* Meal */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="meal"
              />

              {/* Allergy Food */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="allergy_food"
              />

              {/* Have App */}
              <FormSelectDropdown
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="have_app"
              />

              {/* Contact App - App Cpde from app type */}
              <FormSelectDropdown
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="contact_app"
              />

              {/* Contact App Account */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                customStyle="w-full mt-5"
                name="contact_app_account"
              />

              {/* Zipcode */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="zipcode"
              />

              {/* Address */}
              <FormTextInput
                bookingField={bookingField}
                handleFormChange={handleFormChange}
                name="address"
              />
            </Panel>
          </ExpamdElementsArrow>
        </Paper>
      )}
    </div>
  );
};
