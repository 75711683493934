import { t } from "i18next";
import { openWarningNotification } from "src/components";

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateMobileNumber = (countryCode: string, number: string) => {
  switch (countryCode) {
    case "852":
      if (number.length < 8) return false;
      break;
    default:
      break;
  }
  return true;
};

export const validateForm = (
  buyerFormValue: any,
  bookingField: any,
  selectLang: any,
  dateTime: any
) => {
  const { email, firstName, lastName, countryCode, mobileNumber } =
    buyerFormValue;

  // Validate Guide Lang (Custom Form)*
  if (
    bookingField["guide_lang"] &&
    bookingField["guide_lang"]["is_require"] === "True"
  ) {
    if (selectLang === "") {
      openWarningNotification(t("messages.guideLanguageMissing"));
      return false;
    }
  }

  // Validate Buyer Form
  if (email === "") {
    openWarningNotification(t("messages.emailMissing"));
    return false;
  }

  if (firstName === "") {
    openWarningNotification(t("messages.firstNameMissing"));
    return false;
  }
  if (lastName === "") {
    openWarningNotification(t("messages.lastNameMissing"));
    return false;
  }

  if (countryCode === "") {
    openWarningNotification(t("messages.countryCodeMissing"));
    return false;
  }

  if (mobileNumber === "") {
    openWarningNotification(t("messages.mobileNumberMissing"));
    return false;
  }

  // Validate Email*
  if (!validateEmail(email)) {
    openWarningNotification(t("messages.incorrectEmailFormat"));
    return false;
  }

  if (!validateMobileNumber(countryCode, mobileNumber)) {
    openWarningNotification(t("messages.incorrectMobileNumber"));
    return false;
  }

  if (dateTime === "") {
    openWarningNotification(t("messages.dateTimeMissing"));
    return false;
  }

  return true;
};
