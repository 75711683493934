import React from "react";

export const Paper = ({ children, fromPage }: any) => {
  return (
    <div
      className={`min-h-[80px] bg-white w-full ${
        fromPage === "myCoupon" ? `border-[0px]` : `border-[1px] p-[20px]`
      } rounded-md bg-cover text-white bg-top p-[20px]`}
    >
      {children}
    </div>
  );
};
