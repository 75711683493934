import { t } from "i18next";
import React from "react";

const labelClass = "block text-sm font-medium text-slate-700";
const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const FormTextInput = (props: any) => {
  const {
    bookingField = {},
    handleFormChange,
    customStyle = "",
    name: fieldKey,
  } = props;

  const isRequiredFormField =
    bookingField &&
    fieldKey &&
    bookingField[fieldKey] &&
    bookingField[fieldKey]["is_require"] === "True";

  if (!isRequiredFormField) return null;

  return (
    <div>
      {isRequiredFormField && (
        <div className={customStyle}>
          <label className="block mt-5">
            <span className={labelClass}>{t(`kkdayForm.${fieldKey}`)}</span>
            <input
              type="text"
              name={`${fieldKey}`}
              className={inputClass}
              onChange={(e) => handleFormChange(e)}
            />
          </label>
        </div>
      )}
    </div>
  );
};
