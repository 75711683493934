import React from "react";
const { Collapse } = require("antd");

export const ExpamdElementsArrow = ({ children }: any) => {
  const onChange = (key: any | string[]) => {};

  return (
    <Collapse bordered={false} defaultActiveKey={["0"]} onChange={onChange}>
      {children}
    </Collapse>
  );
};
