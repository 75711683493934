import React from "react";
const { Select } = require("antd");

export const SelectDropdown = (props: any) => {
  const {
    defaultValue = "",
    handleChange,
    className = "",
    options = [],
    allowClear = false,
    showArrow = false,
    disabled = false,
    showSearch = false,
    style = {},
    notFoundContent = {},
  } = props;

  return (
    <Select
      showSearch={showSearch}
      style={style}
      defaultValue={defaultValue}
      className={className}
      onChange={handleChange}
      showArrow={showArrow}
      allowClear={allowClear}
      options={options}
      notFoundContent={notFoundContent}
      disabled={disabled}
    />
  );
};
