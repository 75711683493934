export const enTranslation = {
  changeLang: "Change Language",
  en: "English (en)",
  "zh-tw": "简体中文 (zh-tw)",
  "zh-cn": "简体中文 (zh-cn)",
  "zh-hk": "繁體中文 (zh-hk)",
  female: "Female",
  male: "Male",
  explore: "Explore",
  profile: "Membership",
  debitCard: "Debit Card",
  wallet: "Wallet",
  service: "Service",
  exclusiveEvents: "Exclusive Events",
  trendingCoupons: "Trending Coupons",
  newMovies: "New Movies",
  all: "All ",
  coupon: "Coupon",
  "kkday-coupon": "KKDAY Coupon",
  detail: " Detail",
  purchased: "Purchased",
  expiration: "Expiry Date",
  validePeriod: "Validity period",
  membershipBenefit: "Obsidian Store",
  myPoints: "My Points",
  myCoupons: "My Coupons",
  myReferralCode: "My Referral Code",
  settings: "Edit Profile",
  referralProgram: "Referral program",
  newMemberEvents: "新人活動",
  memberEvents: "Member Events",
  exchangePoints: "Points Swap",
  exchangeRate: "Current Exchange Rate 1 HKD : 1 AT",
  languages: "Languages",
  logout: "Log Out",
  cs: "Customer Services",
  email: "Email",
  phone: "Phone Number",
  workingTimes: "Working HOurs",
  wokringTimeDetailOne: "From Monday to Friday（9：30-17：00）。",
  wokringTimeDetailTwo: "Close at Saturday, Sunday and Public Holidays.",
  backUp: "Back",
  from: "From",
  to: "To",
  or: "Or",
  use: "Use",
  paymentOptions: "Payment Options",
  pay: "Pay",
  payme: "Payme",
  topup: "Purchase",
  confirmTopup: "Confirm to top up",
  topUpDone: "Top Up Succeed",
  topUpSuccess: "Top Up Succeed",
  topupNow: "Top up now",
  topupAtpoints: "Top up AT Points",
  topupAmount: "Top up amount (HKD)",
  topupMethod: "Top up method",
  orderNumber: "Order Number",
  doNotRefresh: "Do not refresh the page until the payment is completed...",
  atPoints: "AT Points",
  points: "Points",
  requiredTopUpPoints: "Required AT points to purchase",
  noCouponFound: "Cannot find any coupon yet.",
  checkOut: "Check Out",
  movieShowTimes: "Check out the movie show times",
  buyTicket: "Buy Ticket",
  moviePrice: "Movie Price",
  allVersions: "All versions",
  noMovieShowingTime: "No movie showing time yet",
  noMovieFound: "No movie available yet",
  minute: "Minute",
  setPin: "Set up your 6-digit payment pin",
  otp: "Please insert OTP",
  pin: "Please insert your desired 6-digit payment pin",
  clickToCopy: "Please click to copy the redemption code",
  confirmIsUsed: "Are you sure this code has been redeemed?",
  used: "Used",
  exchangeNow: "Exchange Now",
  exchangeRateTxt: "Exchange Rate",
  confirmExchange: "Confirm Exchange",
  notSelectExchangeItems: "Please select exchange items first",
  exchangeTitle: "Exchange",
  exchangeSuccess: "Exchanged Successfully",
  exchangeFailed: "Failed to exchange, please try again later!",
  referral: {
    title: "My Code",
    form: {
      referralCode: "Referral Code",
      codeConfirm: "Confirm",
      success: "Confirmed to use referral code",
      error: {
        empty: "Referral code cannot be empty",
        self: "Referral code cannot be used",
      },
      used: "You've already used the referral code",
    },
    errors: {
      default: "Referral code cannot be used",
      used: "You've already used the referral code",
    },
    copied: "Copied",
    subtitle: "Benefits",
    promo1: "Follow us to receive the latest promotions.",
    promo2: "Invite your friend(s) to purchase a coupon to receive AT rewards.",
  },
  services: {
    available: "Avilable Amount",
    toByonOpen: "Activate Now on BYON",
    byonChecking: "BYON is verifying",
    byonApproved: "More BYON amount",
    availableMerchants: "Available Merchants",
    openService: "Apply for byonPAYLATER {{type}} Service",
    paymentDesc: "Monthly Repayment after payment holidays",
    paymentNote33:
      "3-Enjoy 3 months of interest-free repayment break with designated merchants, then repay in 3 monthly installments at a monthly rate as low as 0.15%! Enjoy now, pay later.",
    paymentNote66:
      "Enjoy 6 months of interest-free repayment break with designated merchants, then repay in 6 monthly installments at a monthly rate as low as 0.15%! Stress-free spending, more flexible budgeting.",
    extraNote:
      "*The above monthly repayment amount is calculated based on a monthly flat rate of 0.39%. The repayment amount and period shown on the table are for reference only.",
    serviceTitle: "「{{type}}」Payment Holiday",
    serviceDesc: {
      at33: "Enjoy 6 months of interest-free repayment break with designated merchants, then repay in 6 monthly installments at a monthly rate as low as 0.15%! Stress-free spending, more flexible budgeting.",
      at66: "Enjoy 6 months of interest-free repayment break with designated merchants, then repay in 6 monthly installments at a monthly rate as low as 0.15%! Stress-free spending, more flexible budgeting.",
    },
    disclaimer1: "A brand under ORIX Finance Services Hong Kong Limited",
    disclaimer2: "Money lender’s licence no.: 1813/2022",
    disclaimer3:
      "Warning: You have to repay your loans. Don't pay any intermediaries.",
    disclaimer4: "Customer service/complaint hotline: (852) 2862 9119",
    topup: "Top Up",
    activate: "Get Merchant Card",
    cardInfo: "Card Info",
    phoneSet: "Phone set up",
    pinSet: "6-PIN set up",
    verification: "ID Verify",
    toVerify: "Go to Verify",
    scanToPay: "Scan To Pay",
    verifyNote:
      "Please use a computer to visit https://www.globalcash.cn/v4/activate/index to log in and complete the identity verification.",
  },
  ticket: {
    place: "Available Outlets",
    hk: "Hong Kong",
    howToUse: "How to Use",
    exchange_how_to_use: "How to Use Your Voucher",
    inc_and_ninc: "Includes / Excludes",
    cancellation_policy: "Cancellation Policy ",
    introduce_summary: "How to use",
    purchase_summary: "Purchase Summary",
    product_info: "Product Info",
    experience_location: "Experirience location",
    validation: "Validity",
    exchange_validation: "Redemption Period",
    schedule: "Schedule",
    tnc: "Terms & Conditions",
    get: "Get it now",
    use: "Use it now",
    couponInfo: "Coupon Info",
    reminder: "Reminder",
  },
  button: {
    returnToWallet: "Back to Wallet",
    returnToMyPoints: "Back to My Points",
    continueExploring: "Continue to explore",
    editProfile: "Update Profile",
    havePaid: "I have already paid",
    paidWithPayme: "Open Payme to pay",
    paidWithOctopus: "Open Octopus to pay",
    soldOut: "Sold out",
    loading: "Loading...",
    setPin: "Set Pin",
    getOtp: "Get OTP",
    nftCollection: "My NFT Collections",
    showAtBird: "Show",
    showQrCode: "Show QR Code",
    goToMintingPage: "Explore AT Birds to become Obsidian Member",
    confirm: "Confirm",
    cancel: "Cancel",
    kkday: "KKDAY",
    filter: "Filter",
    close: "Close",
    search: "Search",
    select: "Select",
    selected: "Selected",
    packageOptions: "Package Options",
    cancelPackageOptions: "Canncel Package Options",
    viewCouponDetail: "View coupon details",
    bookNow: "Book Now",
    download: "Download Coupon Details",
    payNow: "Pay Now",
    goToTopup: "Go to top up",
    octopus: "Pay with Octopus",
    back: "Back",
  },
  myNftCollection: "My NFT Collections",
  nft: {
    noWalletAddress: "No wallet address found",
    displayDummyNftHeader: "AsiaTop NFT Collection",
    myCollectionHeader: "AT BIRD Collection",
    allCollections: "All NFT Collections",
    outstandingDiscount: "Utilities",
    copied: "",
    confirm: "Confirm",
    cancel: "Cancel",
    "AT Birds": {
      desc1: "Holding AT Bird to upgrade to Obsedian Member",
      desc2: "More AT Bird NFT is coming soon",
    },
  },
  card: {
    tier: "Tier",
    graz: "Welcome",
    jade: "Jade",
    GOLD: "Gold",
    DIAMOND: "Diamond",
    OBSIDIAN: "OBSIDIAN",
    stat: "Let's enjoy the fabulous offers!",
  },
  messages: {
    pleaseWait: "Wait a second",
    comingSoon: "Please stay tuned!",
    congraz: "Congratulations",
    warning: "Hold on",
    error: "Sorry",
    getCodeNoFieldErr: "Please enter your country code and phone number.",
    sendOtpFail:
      "Failed to send OTP, please confirm your country code and phone number.",
    sendOtpSuccess:
      "We have already sent the OTP to your phone number, and the OTP will be valid for 5 mins.",
    verifyOtpFail:
      "Failed to verify the OTP, please confirm your OTP, country code and phone number.",
    loginSuccess: "Successfully logged in.",
    pleaseEnterTopupAmount: "Please fill in top up amount",
    createPaymentFailed: "Failed to create payment",
    updateUserFail: "Failed to update the profile.",
    updateUserSuccess: "Successfully updated profile.",
    noCoupon: "You have redeemed all coupons.",
    approveOrderErr: "Failed to proceed your topup request, please contact us.",
    mylinkPointInsufficient: "Please exchange to At points first",
    confirmPayment: "Please confirm you have already paid.",
    paymentExpired: "Payment code is expired, please try again.",
    couponSoldout: "Coupon is sold out.",
    formNotReady: "Please submit BYON form.",
    cardNotReady: "We are preparing your wallet.",
    tryAgain: "Please try again.",
    requireOtp: "Please insert 6-digit OTP.",
    requirePwd: "Please insert 6-digit pin.",
    pinSet: "Successfully set pin, please select services.",
    formNotApproved: "BYON form is reviewing.",
    obsidianLevelRequired:
      "Your membership level is insufficient, please consider upgrading to Obsidian",
    failedToUserInactive:
      "Your account is inactive, please contact our customer service to reactive your account.",
    failedToVerifyUser: "failed to verify user",
    logout: "Successfully logged out.",
    otpIncorrect: "OTP is incorrect, please try again.",
    failedToUpdatePaymentOrder: "Failed to update payemnet order",
    incorrectEmailFormat: "Incorrect email format",
    incorrectMobileNumber: "Incorrect mobile format",
    emailMissing: "Please fill in email",
    firstNameMissing: "Please fill in first name",
    lastNameMissing: "Please fill in last name",
    countryCodeMissing: "Please fill in country code",
    mobileNumberMissing: "Please fill in mobile number",
    guideLanguageMissing: "Please select guide language",
    eventTimeIsMissing: "Please select event time",
    maxQuotaReached: "You've reached the maximum units",
    minQuotaReached: "Minimum purchase unit of {{minQuantity}} required",
    noVoucherFileFound: "Files does not exist in this voucher",
    requiredFieldMissing: "Required field missing",
    dateTimeMissing: "Please select a date",
    packageMissing: "Please select package",
    invalidDateTimeSelected: "Invalid date selected",
    failedToSelectPackage: "Failed to select package",
    packageNotSelected: "Please select package",
    failedToConfirmOrder: "Failed to confirm order",
    failedToCreatePaymentOrder: "Failed to create payment order",
    failedToDownloadVoucher: "Failed to download voucher",
    failedToRedeemCoupon: "Failed to redeem coupon",
    failedToPurchaseCoupon: "Failed to purchase coupon",
    nft_or_tier_required:
      "Please upgrade your membership tier or get an exclusive NFT from our events",
    disclaimer:
      "Please agree the disclaimer to become a AT Rewards Member firstly",
    successfullyPurchasedCoupon: "Successfully purchased coupon",
    redeemedCouponSuccess: "Successfully redeemed coupon",
  },
  disclaimer: {
    myLink:
      "By clicking “I agree”, you are agreeing to our terms of service and agreeing to become a AT Rewards Member.",
    agree: "I Agree",
    discard: "Discard",
  },
  form: {
    name: "Nickname",
    email: "Email",
    dob: "Date of Birth",
    submit: "Submit",
    save: "Save Profile",
    summary: "Summary",
    countryName: "Country Code",
    phone: "Phone Number",
    code: "OTP",
    register: "Access",
    gender: "Gender",
    getCode: "Get Code",
    enter: "Confirm",
    inviterCode: "Inviter referral code",
  },
  kkdayForm: {
    lang: "Service Language",
    s_date: "Departure date (YYYY-MM-DD)*",
    s_location: "Departure location*",
    e_location: "Arrival location*",
    buyerInfo: "Buyer Information",
    travellerInfo: "Traveller Information",
    pickUpInfo: "Pick-ip Details",
    contactInfo: "Contact Information",
    gender: "Gender*",
    travellerFirstName: "First Name (Traveller)*",
    travellerLastName: "Family Name (Traveller)*",
    native_first_name: "Chinese First Name (Same as Passport)*",
    native_last_name: "Chinese Last Name (Same as Passport)*",
    english_first_name: "English First name (Same as Passport)*",
    english_last_name: "English Last name (Same as Passport)*",
    MTP_no: "Mainland travel permit (Taiwanese only)*",
    id_no: "ID Number*",
    passport_no: "ID Number (passport/ID Card or equivalent identification)*",
    passport_expdate: "Expired date of passport*",
    birth: "Birthday*",
    height: "Height*",
    height_unit: "Unit of height*",
    weight: "Weight*",
    weight_unit: "Unit of weight*",
    shoe: "Shoes*",
    shoe_unit: "Unit of Shoes*",
    shoe_type: "Type of Shoes*",
    glass_degree: "Glass degree*",
    meal: "Meal code*",
    allergy_food: "Allergy food*",
    have_app: "Is there App information*",
    contact_app: "Name code of the App*",
    contact_app_account: "App account information*",
    zipcode: "Zip code*",
    address: "Address*",
    tel_number: "Phone Number (Traveller)*",
    guide_lang: "Service Language*",
    firstName: "First Name (Buyer)*",
    lastName: "Family Name (Buyer)*",
    contactFirstName: "First Name*",
    contactLastName: "Family Name*",
    nationality: "Nationality*",
    email: "Email (Buyer)*",
    mobile: "Phone Number (Buyer)*",
    orderNumber: "Your order number",
    soldOut:
      "The above selected time has already sold out, please select another day",
    specialRequirement: "Special Requirement",
    selectTime: "Select Time",
    selectDateTime: "Select Date",
    selectedDateTime: "Selected Date: ",
    expand: "Click here to fill in the information",
    disclaimer:
      "The above email and phone number will be the point of contact for receiving for order related issues and voucher receiving",
    traffic_type: "Traffic Type*",
    traveler: "Traveler",
    adult: "Adult",
    child: "Child",
    teenager: "Teenager",
    senior: "Senior",
    men: "Men",
    women: "Women",
    default: "Number of people",
  },
  point: {
    pointNumber: "Points",
    enter: "Confirm",
  },
  screeningsType: {
    allTheaterLines: "All theater lines",
    allRegions: "All regions",
    allVersions: "All versions",
  },
  events: {
    nft: "<span>To become obsidian member and enjoy fabulous offers of up to 30% off!</span>",
    drink:
      "<span>Let's go for 𝘼𝙏 𝙍𝙚𝙬𝙖𝙧𝙙𝙨 𝙇𝙪𝙘𝙠𝙮 𝘿𝙧𝙖𝙬!<br>Grab your free drinks!</span>",
    atghnft: "<span>AT BIRD「不得鳥」NFT portal</span>",
    nftflash: "#ExclusiveFlashSale offer for 「𣎴得鳥」NFT’s members",
    maysale: "AT Bird May Flash Sale, up to 50% off",
  },
  modal: {
    pendingTransaction: "Pending Transaction",
    pendingTransactionDone: "Completed Transaction",
    pendingTransactionAmount:
      "You have a pending recharge transaction with an amount of {{amount}} AT. Do you want to continue and complete it?",
    pendingTransactionCompleted:
      "Your recharge transaction has been processed successfully with an amount of {{amount}} AT.",
  },
  redeemNow: "Go To Redeem",
  exchange: {
    exchangeAmount: "Exchange Amount",
    error: {
      ATPBalanceInsufficient: "Insufficient Balance",
      empty: "Exchange amount cannot be empty",
      emptyExchangeOption: "Please select points to convert first",
    },
    selectionTitle: "Which type of points to be selected to convert",
    exchangedFailed: "Please try again later",
    noExchangeOption: "No exchange available on your account currently",
  },
  atpTitle: "聚分俠 ATP",
  earliestAvailableDate: "Earliest Available Date:",
  packageOptions: "Package Options",
  quantityOption: "Quantity",
  timeOption: "Select Time",
  orderSummary: "Order Summary",
  orderTotal: "Order Total",
  totalPaid: "Total Paid: ",
  paidAmount: "Paid Amount",
  productNo: "Product No: {{prodNo}}",
  price: " {{at_points}} AT",
  upTo: "Up to",
  ages: "Ages ({{minAge}} - {{maxAge}})",
};
