import { DownOutlined } from "@ant-design/icons";
import React from "react";

const { Dropdown, Space, Menu } = require("antd");

export const DropdownMenu = (props: any) => {
  const {
    open,
    menuItems,
    className,
    handleMenuClick,
    handleVisibleChange,
    dropdownValue = null,
  } = props;

  const menu = (
    <Menu
      selectedKeys={["sortByLatest"]}
      onClick={handleMenuClick}
      items={menuItems}
    />
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onOpenChange={handleVisibleChange}
      open={open}
      className={className}
      placement="bottomRight"
      dropdownAlign={{ offset: [-40, 4] }}
    >
      <Space>
        <DownOutlined />
        {dropdownValue}
      </Space>
    </Dropdown>
  );
};
