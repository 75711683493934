import { t } from "i18next";
import React from "react";
import { ExpamdElementsArrow, Paper } from "src/components";
const { Collapse } = require("antd");

export const BuyerInfoForm = (props: any) => {
  const { Panel } = Collapse;
  const { buyerFormValue, setBuyerFormValue } = props;
  const labelClass = "block text-sm font-medium text-slate-700";
  const inputClass =
    "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBuyerFormValue({ ...buyerFormValue, [name]: value });
  };

  return (
    <div className="mt-[20px] buyer-form">
      <Paper>
        <h5>{t(`kkdayForm.buyerInfo`)}</h5>
        <ExpamdElementsArrow>
          <Panel showArrow={true} header={t("kkdayForm.expand")} key={0}>
            {/* First Name */}
            <label className="block">
              <span className={labelClass}>{t("kkdayForm.firstName")}</span>
              <input
                type="text"
                name={`firstName`}
                className={`${inputClass}`}
                value={buyerFormValue.firstName}
                onChange={handleChange}
              />
            </label>

            {/* Last Name */}
            <label className="block mt-5">
              <span className={labelClass}>{t("kkdayForm.lastName")}</span>
              <input
                type="text"
                name={`lastName`}
                className={`${inputClass}`}
                value={buyerFormValue.lastName}
                onChange={handleChange}
              />
            </label>

            {/* Mobile */}
            <label className="block mt-5">
              <span className={labelClass}>{t("kkdayForm.mobile")}</span>
              <div className="flex flex-row relative">
                <input
                  type="number"
                  name="countryCode"
                  className={`${inputClass} w-20 mr-2 pl-6`}
                  value={buyerFormValue.countryCode}
                  onChange={handleChange}
                />
                <p className="text-slate-500 absolute bottom-[10px] left-[10px]">
                  +
                </p>
                <input
                  type="number"
                  name="mobileNumber"
                  className={inputClass}
                  value={buyerFormValue.mobileNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </label>

            {/* Email */}
            <label className="block mt-5">
              <span className={labelClass}>{t("kkdayForm.email")}</span>
              <input
                id="kkday-buyer-email"
                type="email"
                name="email"
                className={inputClass}
                placeholder=""
                value={buyerFormValue.email}
                onChange={handleChange}
              />
            </label>

            <div className="clear-both">
              <p className="pt-3 text-at-pink leading-[15px]">
                {t(`kkdayForm.disclaimer`)}
              </p>
            </div>
          </Panel>
        </ExpamdElementsArrow>
      </Paper>
    </div>
  );
};
