import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  openInfoNotification,
  openWarningNotification,
  VoucherSummary,
} from "src/components";
import { PaymentOptions } from "src/components/full-ticket/payment-options";
import { history, useCouponStore } from "src/stores";
import { validateForm } from "src/utils";
import { BuyerInfoForm } from "./buyer-Info";
import { KkdayDatePicker } from "./date-picker";
import { ProductDetails } from "./product-details";
import { SubmitFormButton } from "./subimit-form-button";
import { CustomInfoForm } from "./form/custom-info-form";
import { TravelerInformation } from "./traveler-information";

export const KkdayCouponDetailForm = (props: any) => {
  const { location } = props;
  const { t } = useTranslation();
  const from = (location.state && location.state.from) || null;

  const coupon = (location.state && location.state.coupon) || {};
  const paymentOrderId =
    (location.state && location.state.paymentOrderId) || "";
  const { selectKkdayPackage, confirmKkdayOrder } = useCouponStore();

  const [data, setData] = useState<any>({});
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const [selectedPackageIdx, setSelectedPackageIdx] = useState(-1);
  const [confirmOrderDetails, setConfirmOrderDetails] = useState<any>({}); //Data for form submittion

  // Select package
  const [selectPackage, setSelectPackage] = useState<any>({
    id: null,
    prod_no: "",
    pkg_no: "",
    pkg_name: "",
    item_no: "",
    start_date: "",
    end_date: "",
    at_points: "",
  });

  //  Buyer Info
  const [buyerFormValue, setBuyerFormValue] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
    countryCode: "852",
    mobileNumber: "",
  });

  // Event Time
  const [eventTime, setEventTime] = useState(null);

  // Date Time
  const [dateTime, setDateTime] = useState<any>("");
  const [skuList, setSkuList] = useState<any>([]);

  // Custom
  const [cus01List, setCus01List] = useState<any>([]);
  const [cus02List, setCus02List] = useState<any>([]);

  // Contact
  const [contactList, setContactList] = useState<any>(null); //details

  // Traveler
  const [travelerInfoList, setTravelerInfoList] = useState<any>({});

  // Guilde Lang
  const [selectLang, setSelectLang] = useState<any>("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!coupon || !paymentOrderId) return history.push("/coupons-home");
    coupon && setData(coupon);
  }, [coupon, paymentOrderId, setData]);

  const toPage = () => {
    switch (from) {
      case "myCoupon": //Use Coupon Page
        return "/my-coupons";
      default:
        return "/coupons-home"; //Check Out Coupon Page
    }
  };

  const selectKkdayPkg = async (pkg: any, index: number) => {
    setSelectedPackageIdx(index);
    setSelectPackage(pkg);

    // Removed Selected Package
    if (selectedPackageIdx === index) {
      setSkuList([]);
      return setSelectedPackageIdx(-1);
    }

    if (selectPackage && selectPackage.item_no) {
      setEventTime(null);
      setSkuList([]);
    }

    setIsLoading(true);
    const { coupon_id, name, country } = data;
    const { pkg_no, start_date } = pkg; //end_date may need in more complex product in the future
    const body = {
      coupon_id: coupon_id,
      name: name,
      package_id: pkg_no,
      start_date: start_date,
      country: country,
    };
    const currentSelectPkg = await selectKkdayPackage(body);
    setIsLoading(false);
    if (!currentSelectPkg)
      return openInfoNotification(t("messages.failedToSelectPackage"));
    setConfirmOrderDetails(currentSelectPkg);
  };

  const purchaseCoupon = () => {
    if (!getPaymentOptions || getPaymentOptions.length === 0) {
      return openInfoNotification(t("messages.comingSoon"));
    }
    return setOpenPayment(true);
  };

  const handleSubmitButton = (coupon: any) => {
    // Validate required form fields*
    const validated = validateForm(
      buyerFormValue,
      data["booking_field"],
      selectLang,
      dateTime
    );
    // Check min quantity
    const { unit_quantity_rule } = confirmOrderDetails;
    const { total_rule } = unit_quantity_rule;
    const { min_quantity } = total_rule;
    if (skuList.length < min_quantity)
      return openWarningNotification(
        t("messages.minQuotaReached", { minQuantity: min_quantity })
      );

    if (!validated) return;
    purchaseCoupon();
  };

  const getPaymentOptions = useMemo(() => {
    const { amount, atPoints } = data;
    let paymentOptions: any = [];
    if (amount) {
    }
    if (atPoints) paymentOptions = [...paymentOptions, "at_points"];
    return paymentOptions;
  }, [data]);

  const totalAtPoints = useMemo(() => {
    if (skuList.length === 0) return 0;
    let amount = 0;
    for (let sku of skuList) {
      let atPoints = sku["latestAtPoints"]
        ? sku["latestAtPoints"]
        : sku["at_points"];
      amount = amount + Number(atPoints);
    }
    return amount;
  }, [skuList]);

  const totalSalePrice = useMemo(() => {
    //Sale Price For kkday
    if (skuList.length === 0) return 0;
    let amount = 0;
    for (let sku of skuList) {
      let salePrice = sku["latestSalePrice"]
        ? sku["latestSalePrice"]
        : sku["sale_price"];
      amount = amount + Number(salePrice);
    }
    return amount;
  }, [skuList]);

  const formattedSkuList = useMemo(() => {
    //SKU For Form Submit
    if (skuList.length === 0) return [];
    let newSkuList: any = [];

    for (let sku of skuList) {
      let newSkuObj = {};
      let isExist = false;

      for (let i in newSkuList) {
        if (sku.sku_id === newSkuList[i].sku_id) {
          isExist = true;
          newSkuList[i].qty = newSkuList[i].qty + 1;
          break;
        }
      }
      // is not exist
      if (!isExist) {
        let salePrice = sku["latestSalePrice"]
          ? sku["latestSalePrice"]
          : sku["sale_price"];
        newSkuObj = {
          sku_id: sku.sku_id,
          qty: 1,
          price: Number(salePrice),
        };
        newSkuList = [...newSkuList, newSkuObj];
      }
    }
    return newSkuList;
  }, [skuList]);

  const formattedSkuNameList = useMemo(() => {
    if (skuList.length === 0) return [];
    let newSkuList: any = [];
    for (let sku of skuList) {
      let newSkuObj = {};
      let isExist = false;
      for (let i in newSkuList) {
        if (sku.sku_id === newSkuList[i].sku_id) {
          isExist = true;
          newSkuList[i].qty = newSkuList[i].qty + 1;
          break;
        }
      }
      if (!isExist) {
        newSkuObj = {
          sku_id: sku.sku_id,
          spec_type:
            (sku.specs_ref.length > 0 &&
              sku.specs_ref.map((ref: any) => ref.spec_value_id))[0] ||
            "kkdayForm.default",
          qty: 1,
        };
        newSkuList = [...newSkuList, newSkuObj];
      }
    }
    return newSkuList;
  }, [skuList]);

  const kkdayProps = {
    buyerFormValue,
    cus01List,
    cus02List,
    contactList,
    confirmKkdayOrder,
    dateTime,
    eventTime,
    formattedSkuList,
    formattedSkuNameList,
    confirmOrderDetails,
    paymentOrderId,
    selectLang,
    selectPackage,
    isLoading,
    setIsLoading,
    travelerInfoList,
    totalAtPoints,
    totalSalePrice,
  };

  return (
    <Container
      toPage={toPage()}
      title={`${t("kkday-coupon")}`}
      showExchange={true}
    >
      <div className="w-auto px-[20px]">
        {/* Product Details */}
        <ProductDetails
          bookingField={data["booking_field"] || {}}
          data={data}
          isLoading={isLoading}
          selectLang={selectLang}
          setSelectLang={setSelectLang}
          selectedPackageIdx={selectedPackageIdx}
          setSelectedPackageIdx={setSelectedPackageIdx}
          onSelectCallback={selectKkdayPkg}
        />

        <KkdayDatePicker
          stockSoldOut={data["stock_sold_out"]}
          dateTime={dateTime}
          eventTime={eventTime}
          setEventTime={setEventTime}
          skuList={skuList}
          setSkuList={setSkuList}
          setDateTime={setDateTime}
          selectPackage={selectPackage}
          confirmOrderDetails={confirmOrderDetails}
          totalAtPoints={totalAtPoints}
        />

        {/* Buyer Information Form*/}
        <BuyerInfoForm
          buyerFormValue={buyerFormValue}
          setBuyerFormValue={setBuyerFormValue}
        />

        {/* Custom Information Form*/}
        <CustomInfoForm
          bookingField={data["booking_field"] || {}}
          cus01List={cus01List}
          cus02List={cus02List}
          skuList={skuList}
          setCus01List={setCus01List}
          setCus02List={setCus02List}
          contactList={contactList}
          setContactList={setContactList}
        />

        {/* Traveler Imformation Form */}
        <TravelerInformation
          bookingField={data["booking_field"] || {}}
          dateTime={dateTime}
          travelerInfoList={travelerInfoList}
          setTravelerInfoList={setTravelerInfoList}
        />

        {/* Order Summary Overview + Special Requirement*/}
        <div className="mt-[20px]">
          {selectPackage && (
            <VoucherSummary
              fromPage="kkday-coupon-detail-form"
              data={data}
              endDate={selectPackage["end_date"]}
              eventTime={eventTime}
              formattedSkuNameList={formattedSkuNameList}
              startDate={dateTime}
              skuList={skuList}
            />
          )}
        </div>
      </div>

      {/* Submit Form Button */}
      <SubmitFormButton handleSubmitButton={handleSubmitButton} data={data} />

      {/* Bottom sheet - Show payment options */}
      <PaymentOptions
        coupon={data}
        options={getPaymentOptions}
        open={openPayment}
        setOpen={setOpenPayment}
        kkdayProps={kkdayProps}
      />
    </Container>
  );
};
