import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages/home";
import { CouponsHome } from "./pages/coupons-home";
import { CouponDetail } from "./pages/coupon-detail";
import { CouponPurchased } from "./pages/coupon-purchased";
import { WalletHome } from "./pages/wallet-home";
import { WalletEditProfile } from "./pages/wallet-edit-profile.tsx";
import { WalletCoupons } from "./pages/wallet-coupons.tsx";
import { PointExchange } from "./pages/point-exchange";
import { LoginPage } from "./pages/login/index";
import AuthProvider from "./components/auth-provider";
import { CouponUse } from "./pages/coupon-use";
import { MovieIndex, MovieDetail } from "./pages/movie";
import {
  MovieShowingTimeIndex,
  MovieShowingDetail,
} from "./pages/movie-screenings";
import { Referral } from "./pages/wallet-referral-program";

import { ServiceHome } from "./pages/service-home";
import { ServiceTopUp } from "./pages/service-topup";
import { ServiceDetail } from "./pages/service-detail";
import { ServiceUse } from "./pages/service-use";
import { NftBirdsCollection } from "./pages/ntf-collections";
import { MemberZone } from "./pages/member-zone";
import { KkdayCouponDetailForm } from "./pages/coupon-detail-kkday";
import { ByonServiceHome } from "./pages/byon-service-home";
import { ByonServiceScan } from "./pages/byon-service-scan";
import { MyLinkExchange } from "./pages/exchange/mylink";
import { ATPExchange } from "./pages/exchange/atp";
import { TopUpAtPoints } from "./pages/top-up-at-points";
import { TopUpDone } from "./pages/top-up-done";
import { MyLinkEntrance } from "./pages/my-link-entrance";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        {/* Coupons */}
        <Route path={"/"} exact component={Home} />
        <Route path={"/coupons-home"} exact component={CouponsHome} />
        <Route path={"/coupon-detail"} exact component={CouponDetail} />
        <Route
          path={"/kkday-coupon-detail-form"}
          exact
          component={KkdayCouponDetailForm}
        />
        <Route
          path={"/coupon-purchased"}
          exact
          component={AuthProvider(CouponPurchased)}
        />

        <Route
          path={"/top-up-done"}
          exact
          component={AuthProvider(TopUpDone)}
        />

        {/* Wallet */}
        <Route path={"/wallet"} exact component={AuthProvider(WalletHome)} />
        <Route
          path={"/edit-profile"}
          exact
          component={AuthProvider(WalletEditProfile)}
        />
        <Route
          path={"/my-coupons"}
          exact
          component={AuthProvider(WalletCoupons)}
        />
        <Route path={"/use-coupon"} exact component={AuthProvider(CouponUse)} />
        <Route
          path={"/point-exchange"}
          exact
          component={AuthProvider(PointExchange)}
        />
        <Route
          path={"/top-up-atpoints"}
          exact
          component={AuthProvider(TopUpAtPoints)}
        />

        {/* Service */}
        <Route path={"/service"} exact component={AuthProvider(ServiceHome)} />
        <Route
          path={"/service-top-up"}
          exact
          component={AuthProvider(ServiceTopUp)}
        />
        <Route
          path={"/service-detail"}
          exact
          component={AuthProvider(ServiceDetail)}
        />
        <Route
          path={"/service-scan"}
          exact
          component={AuthProvider(ServiceUse)}
        />
        {/* For BYON */}
        <Route path={"/byon-service"} exact component={ByonServiceHome} />
        <Route path={"/byon-service-scan"} exact component={ByonServiceScan} />

        {/* Login */}
        <Route path={"/login"} exact component={LoginPage} />

        <Route path={"/movie-list"} exact component={MovieIndex} />
        <Route path={"/movie-detail"} exact component={MovieDetail} />
        <Route
          path={"/movie-showing-time"}
          exact
          component={MovieShowingTimeIndex}
        />
        <Route
          path={"/movie-showing-detail"}
          exact
          component={MovieShowingDetail}
        />

        {/* member zone  */}
        <Route path={"/member-zone"} exact component={MemberZone} />
        {/*  NFT Collections  */}
        <Route path={"/nft-collection"} exact component={NftBirdsCollection} />
        <Route path={"/referral"} exact component={AuthProvider(Referral)} />

        {/* Exchange */}
        <Route path={"/exchange/my-link"} exact component={MyLinkExchange} />
        <Route path={"/exchange/at-points"} exact component={ATPExchange} />

        {/* My Link */}
        <Route path={"/entrance"} exact component={MyLinkEntrance} />

        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
