import { t } from "i18next";
import React, { useState } from "react";
import { VoucherSummary } from "../voucher-summary";
import { useCouponStore } from "src/stores";
import { openWarningNotification } from "../notification";
import { LoadingIcon } from "../loading-icon";

export const KkdayVoucherSummary = (props: any) => {
  const { data, redeemedKkdayCoupon } = props;
  const { getKkdayVoucherDetail, getKkdayVoucherDownloadUrl } =
    useCouponStore();
  const [isLoading, setIsLoading] = useState(false);

  const downloadVoucher = () => {
    setIsLoading(true);
    const { partnerOrderId } = data;

    getKkdayVoucherDetail({ order_no: partnerOrderId }).then((result) => {
      setIsLoading(false);
      if (!result) return;
      const { order_file_id } = result;
      if (!order_file_id)
        return openWarningNotification(t("messages.noVoucherFileFound"));

      getKkdayVoucherDownloadUrl(partnerOrderId, order_file_id).then(
        (result) => {
          setIsLoading(false);
          if (!result) return;
          const { download_url } = result;
          const downloadUrl = window.open(download_url, "_blank");
          if (!downloadUrl)
            return openWarningNotification(
              t("messages.failedToDownloadVoucher")
            );
        }
      );
    });
  };
  return (
    <div className="w-100">
      {data && (
        <VoucherSummary
          fromPage="myCoupon"
          allowLabel={false}
          data={data}
          startDate={data["start_date"]}
          endDate={data["end_date"]}
        />
      )}
      <button
        className={`full mb-6 py-3 text-xs mt-4 ${
          !redeemedKkdayCoupon ? "secondary" : "primary"
        }`}
        onClick={downloadVoucher}
      >
        {isLoading ? (
          <div className="flex justify-center items-center">
            <LoadingIcon size={20} color={"white"} />
          </div>
        ) : (
          t(`button.download`)
        )}
      </button>
    </div>
  );
};
