import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import {
  errorHandling,
  isResultSuccess,
  refreshNewToken,
  setHeader,
} from "./api";

export class CouponStore {
  async getCoupons(args?: any) {
    const url = `${Configs.apiUrl}/coupons/get${args ? args : ""}`;
    try {
      const result: any = await axios.get(url);
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getCouponsV2(args?: any) {
    try {
      const url = `${Configs.apiUrl}/v2/coupons/get${args ? args : ""}`;
      const result: any = await axios.get(url, await setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (err) {
      return errorHandling(err);
    }
  }

  async getMezzofyCoupon(coupon_id: string) {
    try {
      const url = `${Configs.apiUrl}/mezzofy/coupon/get-one?coupon_id=${coupon_id}`;
      const result: any = await axios.get(url, await setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
      // return { success: true, data: result["data"]["data"] };
    } catch (err) {
      return errorHandling(err);
    }
  }

  async getUserCoupons() {
    const url = `${Configs.apiUrl}/user/coupons`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getUserCouponsV2() {
    const url = `${Configs.apiUrl}/v2/user/coupons`;
    try {
      const result: any = await axios.get(url, await setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
      // return { success: true, data: result["data"]["data"] };
    } catch (err) {
      return errorHandling(err);
    }
  }

  async issueCoupon(payload: any) {
    const url = `${Configs.apiUrl}/user/coupon/issue`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async issueMezzofyCoupon(payload: any) {
    const url = `${Configs.apiUrl}/user/mezzofy/coupon/issue`;
    try {
      const result: any = await axios.post(url, payload, await setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["success"];
      // return { success: result["data"]["success"] };
    } catch (e) {
      return errorHandling(e);
    }
  }

  async redeemCoupon(payload: any) {
    const url = `${Configs.apiUrl}/user/coupon/redeem`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async redeemMezzofyCoupon(payload: any) {
    const url = `${Configs.apiUrl}/user/mezzofy/coupon/redeem`;
    try {
      const result: any = await axios.post(url, payload, await setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["success"];
    } catch (err) {
      return errorHandling(err);
    }
  }

  // KKDAY COUPONS
  async confirmKkdayOrder(payload: any) {
    const url = `${Configs.apiUrl}/kkday/confirm-order`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getKkdayCoupons() {
    const url = `${Configs.apiUrl}/kkday/coupons/get`;
    try {
      const result: any = await axios.get(url);
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"]["coupons"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getKkdayCoupon(args: any) {
    const url = `${Configs.apiUrl}/kkday/coupons/get-one${args ? args : ""}`;
    try {
      const result: any = await axios.get(url);
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["coupon"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getKkdayVoucherDownloadUrl(order_no: string, order_file_id: string) {
    const url = `${Configs.apiUrl}/kkday/coupon/download?order_no=${order_no}&order_file_id=${order_file_id}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getKkdayVoucherDetail(payload: any) {
    const url = `${Configs.apiUrl}/kkday/voucher/get`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getUserKkdayCoupons() {
    const url = `${Configs.apiUrl}/user/kkday/coupons`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async redeemKkdayCoupon(payload: any) {
    const url = `${Configs.apiUrl}/user/kkday/coupon/redeem`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      refreshNewToken(result);
      return result["data"]["success"];
    } catch (e) {
      return errorHandling(e);
    }
  }

  async selectKkdayPackage(payload: any) {
    const url = `${Configs.apiUrl}/kkday/select-package`;
    try {
      const result: any = await axios.post(url, payload, setHeader());
      if (!isResultSuccess(result)) return generalError;
      return result["data"]["data"];
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_COUPON = "couponStore";
