import { t } from "i18next";
import React from "react";
import { SelectDropdown } from "src/components";

import {
  appTypeList,
  genderList,
  heightUnitList,
  langList,
  shoeTypeList,
  shoeUnitList,
  trueFalseList,
  weightUnitList,
} from "src/utils/language";

const labelClass = "block text-sm font-medium text-slate-700";
const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const FormSelectDropdown = (props: any) => {
  const {
    bookingField = {},
    handleFormChange = {},
    defaultValue = " - ",
    customStyle = "",
    name: fieldKey,
  } = props;

  let selectionList: any = [];

  switch (fieldKey) {
    case "gender":
      selectionList = genderList;
      break;
    case "guide_lang":
      selectionList = langList;
      break;
    case "height_unit":
      selectionList = heightUnitList;
      break;
    case "weight_unit":
      selectionList = weightUnitList;
      break;
    case "shoe_type":
      selectionList = shoeTypeList;
      break;
    case "shoe_unit":
      selectionList = shoeUnitList;
      break;
    case "have_app":
      selectionList = trueFalseList;
      break;
    case "contact_app":
      selectionList = appTypeList;
      break;
    default:
      break;
  }

  const fieldIsRequired =
    bookingField &&
    fieldKey &&
    bookingField[fieldKey] &&
    bookingField[fieldKey]["is_require"] === "True";

  if (!fieldIsRequired) return null;

  return (
    fieldIsRequired && (
      <div className={customStyle}>
        <label className="block">
          <span className={labelClass}>{t(`kkdayForm.${fieldKey}`)}</span>
          <div className="mt-1">
            <SelectDropdown
              showArrow
              notFoundContent={""}
              defaultValue={defaultValue}
              className={inputClass}
              handleChange={(e: any) => handleFormChange(e, "select", fieldKey)}
              options={selectionList}
            />
          </div>
        </label>
      </div>
    )
  );
};
