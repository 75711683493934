import React from "react";
import { t } from "i18next";
import { SeperateLine } from "src/components";

export const TotalPaidAmount = (props: any) => {
  return (
    <React.Fragment>
      {/* Tatal Paid Amount */}
      <div className="flex flex-1 flex-col justify-around items-end mt-[10px]">
        <p className="mt-2">
          <span className="text-amber-300">$</span>
          <span className="text-xs text-slate-400 ml-1">{t(`orderTotal`)}</span>
          <span className="mt-2 text-black">
            {` ${props.totalAtPoints} `}AT
          </span>
        </p>
        <SeperateLine />
        <p className="mt-[5px] text-black">
          {t(`totalPaid`)}
          <span className="ml-[5px] text-at-pink text-[24px] font-[800] !tracking-[-1px]">
            {props.totalAtPoints} AT
          </span>
        </p>
      </div>
    </React.Fragment>
  );
};
