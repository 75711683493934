import { t } from "i18next";
import React, { useState } from "react";
import { history, useCouponStore } from "src/stores";
import { LoadingIcon } from "../loading-icon";
import { openWarningNotification } from "../notification";
const { Popconfirm } = require("antd");

export const ConfirmToUseButton = (props: any) => {
  const { data } = props;
  const { isUsed, orderId } = data;

  const { redeemKkdayCoupon } = useCouponStore();
  const [loading, setLoading] = useState(false);

  const confirm = async (e: React.MouseEvent<HTMLElement>, orderId: string) => {
    try {
      setLoading(true);
      await redeemKkdayCoupon({ order_id: orderId });
      setLoading(false);
      history.push("/my-coupons");
    } catch (err) {
      return openWarningNotification(t("messages.failedToRedeemCoupon"));
    }
  };

  return (
    <div className="fixed top-[90%] px-8 w-screen">
      {loading && (
        <div className="flex justify-center pt-5">
          <LoadingIcon />
        </div>
      )}

      {!isUsed ? (
        <Popconfirm
          title={t("confirmIsUsed")}
          onConfirm={(e: any) => confirm(e, orderId)}
          onCancel={() => null}
          okText={t(`button.confirm`)}
          cancelText={t(`button.cancel`)}
        >
          <button className="full py-3 text-xs">{t("confirmIsUsed")}</button>
        </Popconfirm>
      ) : (
        <button className="disabled text-xs cursor-not-allowed full py-3 secondary !bg-white !text-at-pink drop-shadow-md">
          {t("used")}
        </button>
      )}
    </div>
  );
};
