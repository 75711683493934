import React from "react";
import { ContactForm } from "./contact-form";
import { Cus02Form } from "./cus02-form";
import { Cus01Form } from "./cus01-form";

export const CustomInfoForm = (props: any) => {
  const { bookingField = {}, skuList = [] } = props;
  const { cus_type = {} } = bookingField;
  const { list_option = [] } = cus_type;

  const isCus01FormRequired = list_option.indexOf("cus_01") !== -1;
  const isCus02FormRequired = list_option.indexOf("cus_02") !== -1;
  const isContactFormRequired = list_option.indexOf("contact") !== -1;

  /*
  1. cus_01 (Lead traveler) 旅客代表人 (Buyer)
  2. cus_02 (Every traveler) 每位旅客 (Traveller)
  3. send (Sending info) 旅客聯絡資料 (Contact)
  4. contact(Contact info) 旅客寄送資料 (Sent)
  */

  return (
    <div>
      {/* Cus01 Form */}
      {isCus01FormRequired &&
        skuList
          .sort((a: any, b: any) => {
            return a.spec_type.localeCompare(b.spec_type);
          })
          .map((sku: any, idx: number) => {
            return (
              <div key={idx}>
                <Cus01Form
                  personCount={idx + 1}
                  cus01Index={idx}
                  sku={sku}
                  {...props}
                />
              </div>
            );
          })}

      {/* Cus02 Form */}
      {isCus02FormRequired &&
        skuList
          .sort((a: any, b: any) => {
            return a.spec_type.localeCompare(b.spec_type);
          })
          .map((sku: any, idx: number) => {
            return (
              <div key={idx}>
                <Cus02Form
                  personCount={idx + 1}
                  cus02Index={idx}
                  sku={sku}
                  {...props}
                />
              </div>
            );
          })}

      {/* Contact Information */}
      {isContactFormRequired && <ContactForm {...props} />}
    </div>
  );
};
