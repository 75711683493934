import { t } from "i18next";
import React from "react";
import { ExpamdElementsArrow, Paper } from "src/components";
import { FormTextInput } from "./form-text-input";
import { FormSelectDropdown } from "./form-select-dropdown";
import { MobileNnumberInputfield } from "./mobile-number-inputfield";
import { formatSpecRefTxt } from "src/utils";

const { Collapse } = require("antd");

export const Cus01Form = (props: any) => {
  const { Panel } = Collapse;
  const {
    sku,
    personCount,
    cus01Index,
    cus01List,
    setCus01List,
    bookingField,
  } = props;

  const handleChange = (e: any, type?: string, selectType?: any) => {
    let name = null;
    let value = null;

    if (type === "select") {
      name = selectType;
      value = e;
    }

    if (type !== "select") {
      name = e.target.name;
      value = e.target.value;
    }

    let newCus02Obj = {
      ...cus01List[cus01Index],
      cus_type: "cus_01",
      [name]: value,
    };
    let newCus01List = [];
    newCus01List = cus01List;
    newCus01List[cus01Index] = newCus02Obj;
    setCus01List(newCus01List);
  };

  const { spec_rule, specs_ref } = sku;
  const { max_age = "", min_age = "" } = spec_rule;

  return (
    <div className="mt-[20px] traveller-form">
      {bookingField && (
        <Paper>
          <h5>
            {t(`kkdayForm.travellerInfo`)} {`(${personCount}) `}
          </h5>
          <ExpamdElementsArrow>
            <Panel showArrow={true} header={t("kkdayForm.expand")} key={0}>
              <div>
                {specs_ref.length > 0 &&
                  specs_ref.map((ref: any, i: number) => (
                    <h5 key={i}>
                      {t(formatSpecRefTxt(ref.spec_value_id))}
                      <span className="ml-2">
                        {min_age &&
                          max_age &&
                          t("ages", { minAge: min_age, maxAge: max_age })}
                      </span>
                    </h5>
                  ))}

                <FormTextInput
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                  name="english_first_name"
                />

                {/* English Lsdt Name */}
                <FormTextInput
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                  name="english_last_name"
                />

                {/* Native First Name */}
                <FormTextInput
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                  name="native_first_name"
                />

                {/* Native Last Name */}
                <FormTextInput
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                  name="native_last_name"
                />

                {/*Tel Country Code & Tel Number */}
                <MobileNnumberInputfield
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                />

                {/* Nationality */}
                <FormTextInput
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                  name="nationality"
                />

                {/* Gender */}
                <FormSelectDropdown
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                  customStyle="w-full mt-5"
                  name="gender"
                />

                {/* Guide Lang */}
                {/* <FormSelectDropdown
                  bookingField={bookingField}
                  handleFormChange={handleChange}
                  defaultValue={t("en")}
                  customStyle="w-full mt-5"
                  name="guide_lang"
                /> */}
              </div>
            </Panel>
          </ExpamdElementsArrow>
        </Paper>
      )}
    </div>
  );
};
