import { t } from "i18next";
export const appTypeList = [
  { value: "0006", label: "SnapChat" },
  { value: "0007", label: "Facebook Messenger" },
  { value: "0004", label: "Kakao" },
  { value: "0005", label: "Viber" },
];

export const genderList = [
  { value: "M", label: t("female") },
  { value: "F", label: t("male") },
];

export const heightUnitList = [
  { value: "01", label: "cm" },
  { value: "02", label: "ft" },
];

export const langList = [
  { value: "en", label: t("en") },
  { value: "zh-cn", label: t("zh-cn") },
  { value: "zh-tw", label: t("zh-tw") },
  { value: "zh-hk", label: t("zh-hk") },
];

// Meal
export const mealList = [
  { value: "0001", label: "Contains meat" },
  { value: "0002", label: "Vegetarian" },
  { value: "0003", label: "Muslim Meal" },
  { value: "0004", label: "Kosher Meal" },
];

export const pickUpTypeList = [
  { value: "pickup_04", label: "客人指定地點接送" },
];

export const trueFalseList = [
  { value: "True", label: "Yes" },
  { value: "False", label: "No" },
];

// Shoe Type
export const shoeTypeList = [
  { value: "M", label: t("men") },
  { value: "W", label: t("women") },
  { value: "C", label: t("child") },
];

// Shoe Unit
export const shoeUnitList = [
  { value: "01", label: "US" },
  { value: "02", label: "EU" },
  { value: "03", label: "JP/CM" },
];

export const shoeOptionList = [
  { value: "man", label: "US" },
  { value: "women", label: "EU" },
  { value: "child", label: "JP/CM" },
];

// Shoe
export const shoeList: any = [
  {
    key: "1",
    label: t("men"),
    children: [
      {
        key: "1-1",
        label: "6 - 14.5 (US)",
      },
      {
        key: "1-2",
        label: "39.5 - 48 (EU)",
      },
      {
        key: "1-3",
        label: "24.5 - 33 (JP/CM)",
      },
    ],
  },
  {
    key: "2",
    label: t("women"),
    children: [
      {
        key: "2-1",
        label: "4 - 11 (US)",
      },
      {
        key: "2-2",
        label: "34 - 41 (EU)",
      },
      {
        key: "2-3",
        label: "21 - 30 (JP/CM)",
      },
    ],
  },
  {
    key: "3",

    label: t("child"),
    children: [
      {
        key: "3-1",
        label: "2.5 - 5.5 (US)",
      },
      {
        key: "3-2",
        label: "19 - 25 (EU)",
      },
      {
        key: "3-3",
        label: "11 - 15 (JP/CM)",
      },
    ],
  },
];

export const weightUnitList = [
  { value: "01", label: "kg" },
  { value: "02", label: "lb" },
];
