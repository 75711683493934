import { t } from "i18next";
import React from "react";
import moment from "moment";

export const Info = (props: any) => {
  const { couponTitle, atPoints, expirationDate, earliestAvailableDate } =
    props;

  return (
    <div className="w-full text-center pt-3 clear-both">
      <p className="break-words">{couponTitle}</p>
      <div className="flex flex-row justify-center py-[1px] items-end">
        {atPoints && (
          <p className="text-black text-xl tracking-tight rainbow">
            {atPoints} <span className="text-sm text-black">AT</span>
          </p>
        )}
      </div>

      {expirationDate && (
        <p className="text-[9px] text-slate-400 pt-3">
          {t("expiration")}: {moment(expirationDate).format("Do MMMM YYYY")}
        </p>
      )}

      {earliestAvailableDate && (
        <p className="text-[9px] text-slate-400 pt-3">
          {t("earliestAvailableDate")}
          {moment(earliestAvailableDate).format("Do MMMM YYYY")}
        </p>
      )}
    </div>
  );
};
