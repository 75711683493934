import { t } from "i18next";
import React, { useMemo } from "react";
import { langList } from "src/utils/language";
import { ProductDescription } from "./product-description";
import { GuideLangSelectMenu } from "./guide-lang-select-menu";

export const ProductDetails = (props: any) => {
  const { data, bookingField } = props;

  const guideLanglist = useMemo(() => {
    if (!bookingField) return langList;
    let newguideLangOptList: any = [];

    const langListOption =
      bookingField &&
      bookingField["guide_lang"] &&
      bookingField["guide_lang"]["list_option"] &&
      bookingField["guide_lang"]["list_option"];

    if (!langListOption) return langList;
    // Restructure the latest list option (if available)
    for (let option of langListOption) {
      const { code } = option;
      newguideLangOptList = [
        ...newguideLangOptList,
        { value: code, label: t(code) },
      ];
    }
    return newguideLangOptList;
  }, [bookingField]);

  return (
    <React.Fragment>
      <div className="pt-[20px]">
        {/* Product Description  */}
        <ProductDescription data={data} />
      </div>

      {/* Language + Navagiate to package option */}
      <GuideLangSelectMenu guideLanglist={guideLanglist} {...props} />
    </React.Fragment>
  );
};
