import { t } from "i18next";
import React from "react";
const labelClass = "block text-sm font-medium text-slate-700";
const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const MobileNnumberInputfield = (props: any) => {
  const { bookingField, handleChange } = props;

  const isMobileNumRequired =
    bookingField["tel_country_code"] &&
    bookingField["tel_country_code"]["is_require"] === "True";

  if (!isMobileNumRequired) return null;

  return (
    isMobileNumRequired && (
      <div>
        <label className="block mt-5">
          <span className={labelClass}>{t("kkdayForm.tel_number")}</span>
          <div className="flex flex-row relative">
            <input
              type="number"
              name="tel_country_code"
              defaultValue={"852"}
              className={`${inputClass} w-20 mr-2 pl-6`}
              onChange={handleChange}
            />
            <p className="text-slate-500 absolute bottom-[10px] left-[10px]">
              +
            </p>
            <input
              type="number"
              name="tel_number"
              className={inputClass}
              onChange={handleChange}
              required
            />
          </div>
        </label>
      </div>
    )
  );
};
