import React from "react";

export const EventTimeOptionButton = (props: any) => {
  const { eventTime, buttonName, selectEventTime } = props;

  return (
    <button
      className={`${
        eventTime === buttonName ? "secondary" : "primary"
      } py-1 mr-3`}
      onClick={(e) => selectEventTime(e, buttonName)}
    >
      <span className="text-xs">{buttonName}</span>
    </button>
  );
};
