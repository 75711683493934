import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import {
  BottomModal,
  LoadingIcon,
  TopUpSummary,
  openNotificationWithIcon,
} from "src/components";
import { AIRWALLET_URL } from "src/config";
import { getName } from "src/utils";

export const AirWallexModal = (props: any) => {
  const {
    setOpen,
    open,
    paymentDetails,
    checkPaymentOrder,
    updatePaymentOrder,
    approveOrder,
    selectedPayment,
  } = props;
  const { client_secret = "", id } = paymentDetails;
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);

  const checkPayment = useCallback(
    async (autoFetch = false) => {
      if (loading) return;
      if (!paymentDetails["order_id"]) return;
      setLoading(true);
      const order = await checkPaymentOrder({
        orderId: paymentDetails["order_id"],
      });
      const status = order["status"];
      switch (status) {
        case "REQUIRES_PAYMENT_METHOD": // waiting for payment
          !autoFetch &&
            openNotificationWithIcon(
              "warning",
              t("messages.warning"),
              t("messages.confirmPayment")
            );
          break;
        case "SUCCEEDED": // success
          await approveOrder(paymentDetails["order_id"]);
          setOpen(false);
          break;
        case "CANCELLED": // expired
          await updatePaymentOrder({
            orderId: paymentDetails["order_id"],
            status: "cancelled",
          });
          openNotificationWithIcon(
            "warning",
            t("messages.warning"),
            t("messages.paymentExpired")
          );
          setOpen(false);
          break;
      }
      setLoading(false);
    },
    [
      approveOrder,
      checkPaymentOrder,
      loading,
      setLoading,
      paymentDetails,
      setOpen,
      updatePaymentOrder,
    ]
  );

  const cancelPayment = async () => {
    await updatePaymentOrder({
      orderId: paymentDetails["order_id"],
      status: "cancelled",
    });
  };

  const openAirWallexCb = useCallback(() => {
    window.open(
      `${AIRWALLET_URL}/?id=${id}&client_secret=${client_secret}&currency=HKD`
    );
  }, [client_secret, id]);

  // Keep using query to check payment
  useEffect(() => {
    if (loading) return;
    const interval = setInterval(() => {
      checkPayment(true);
    }, 5000);
    return () => clearInterval(interval);
  }, [loading, checkPayment]);

  // Open AirWallet URL at init
  useEffect(() => {
    if (init) return;
    if (!id || !client_secret) return;
    openAirWallexCb();
    setInit(true);
  }, [id, client_secret, init, setInit, openAirWallexCb]);

  return (
    <BottomModal
      setOpen={setOpen}
      open={open}
      onCloseAction={() => cancelPayment()}
    >
      <div className="p-5 text-center mx-auto min-h-[150px] flex flex-col">
        <h5 className="text-center pb-[20px]">{getName(selectedPayment)}</h5>

        {paymentDetails && (
          <TopUpSummary
            selectedPayment={selectedPayment}
            topupAtpAmount={paymentDetails.amount}
            paymentOrderId={paymentDetails.order_id}
          />
        )}

        {/* Button */}
        {paymentDetails["order_id"] && (
          <div className="flex flex-col">
            <div className="flex flex-col flex-1 justify-center items-center pb-[20px]">
              <p className="pt-5">{t("doNotRefresh")}</p>
            </div>
            <button
              className="primary full py-3 flex flex-row justify-center items-center"
              onClick={() => checkPayment()}
            >
              {loading ? (
                <LoadingIcon size={15} color="#fff" />
              ) : (
                t("button.havePaid")
              )}
            </button>

            <div className="flex flex-col flex-1 justify-center items-center">
              <p
                className="pt-5 text-at-pink"
                onClick={() => openAirWallexCb()}
              >
                {t("button.payNow")}
              </p>
            </div>
          </div>
        )}

        {!paymentDetails["order_id"] && (
          <div className="flex justify-center pt-5">
            <LoadingIcon />
          </div>
        )}
      </div>
    </BottomModal>
  );
};
