import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  VoucherSummary,
  SmallTicket,
  LoadingIcon,
} from "src/components";
import { openWarningNotification } from "src/components";
import { history, useCouponStore } from "src/stores";

export const CouponPurchased = (props: any) => {
  const { location } = props;
  const { t } = useTranslation();
  let { coupon, formData } = location.state;
  const { getKkdayVoucherDetail, getKkdayVoucherDownloadUrl } =
    useCouponStore();
  const [voucherDetails, setVoucherDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!coupon) {
      setIsLoading(false);
      return history.push("/coupons-home");
    }
    if (!formData) return setIsLoading(false);

    const { partner_order_id } = formData;
    setIsLoading(false);
    if (!partner_order_id) return;
    return setVoucherDetails({ ...coupon, ...formData });
  }, [coupon, formData, setIsLoading, setVoucherDetails]);

  const downloadVoucher = () => {
    setIsLoading(true);
    const { partner_order_id } = formData;
    getKkdayVoucherDetail({ order_no: partner_order_id }).then((result) => {
      setIsLoading(false);
      if (!result) return;
      if (result.length === 0)
        //Check File
        return openWarningNotification(t("messages.noVoucherFileFound"));
      const { order_file_id } = result;

      if (!order_file_id)
        return openWarningNotification(t("messages.noVoucherFileFound"));

      getKkdayVoucherDownloadUrl(partner_order_id, order_file_id).then(
        (result) => {
          setIsLoading(false);
          if (!result) return;
          const { download_url } = result;
          const downloadUrl = window.open(download_url, "_blank");
          if (!downloadUrl)
            return openWarningNotification(
              t("messages.failedToDownloadVoucher")
            );
        }
      );
    });
  };

  const isKkdayCoupon = useMemo(() => {
    return coupon && coupon.type === "kkday";
  }, [coupon]);

  return (
    <Container title={`${t("coupon")}${t("purchased")}`}>
      {isLoading && (
        <div className="flex justify-center flex-1 py-5">
          <LoadingIcon />
        </div>
      )}

      {!isLoading && !isKkdayCoupon && (
        <div className="px-5 py-5">
          <SmallTicket
            id={coupon.id}
            couponId={coupon.coupon_id}
            couponCode={coupon.coupon_code}
            couponTitle={coupon.couponTitle}
            couponTitleEn={coupon.couponTitleEn}
            couponIcon={coupon.couponIcon}
            amount={coupon.amount}
            expirationDate={coupon.expirationDate}
            redemption_type={coupon.redemption_type}
            type={coupon.type}
          />
        </div>
      )}

      {/* KKDAY Coupon - Order Summary */}
      {isKkdayCoupon && voucherDetails && (
        <div className="px-5 py-5">
          <VoucherSummary
            fromPage="coupon-purchased"
            allowLabel={false}
            atPoints={voucherDetails["at_points"]}
            data={coupon}
            eventTime={voucherDetails["event_time"]}
            formattedSkuNameList={voucherDetails["sku_name_list"]}
            startDate={voucherDetails["start_date"]}
            endDate={voucherDetails["end_date"]}
          />
        </div>
      )}

      <div>
        {!isLoading && !isKkdayCoupon && (
          <div className="flex flex-col items-center">
            <div className="mt-5 mb-2 w-32 h-32 overflow-hidden ml-4">
              <img
                src={"/assets/location.png"}
                alt={"coffee-icon"}
                className=" w-full h-full object-cover"
              />
            </div>
            <div className="flex flex-col items-center">
              <button
                className="mt-3 py-2 mb-2 w-[150px]"
                onClick={() => history.push("/wallet")}
              >
                {t("button.returnToWallet")}
              </button>

              <button
                className="mt-1 py-2 mb-2 w-[150px] secondary"
                onClick={() => history.push("/coupons-home")}
              >
                {t("button.continueExploring")}
              </button>
            </div>
          </div>
        )}

        {/* Download Button (KKDAY) */}
        {!isLoading && isKkdayCoupon && (
          <div className="flex flex-col justify-center items-center">
            {voucherDetails && (
              <button className="mt-8 py-2 w-[150px]" onClick={downloadVoucher}>
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <LoadingIcon size={20} color={"white"} />
                  </div>
                ) : (
                  t("button.download")
                )}
              </button>
            )}
            <button
              className="mt-3 py-2 mb-2 w-[150px] secondary"
              onClick={() => history.push("/wallet")}
            >
              {t("button.returnToWallet")}
            </button>
          </div>
        )}
      </div>
    </Container>
  );
};
