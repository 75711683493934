import { t } from "i18next";
import React from "react";
import { ExpamdElementsArrow, Paper, SelectDropdown } from "src/components";
import { pickUpTypeList } from "src/utils/language";

const { Collapse } = require("antd");
const labelClass = "block text-sm font-medium text-slate-700";
const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const TravelerInfoForm = (props: any) => {
  const { Panel } = Collapse;
  const { bookingField, dateTime, travelerInfoList, setTravelerInfoList } =
    props;
  const { traffic = {} } = bookingField;
  const { car } = traffic;

  const handleChange = (e: any, type?: string, selectType?: string) => {
    let name = null;
    let value = null;

    if (type === "select") {
      name = selectType;
      value = e;
    }

    if (type !== "select") {
      name = e.target.name;
      value = e.target.value;
    }

    let newTravelerInfoObj: any = {
      ...travelerInfoList,
      s_date: dateTime,
      [name]: value,
    };
    return setTravelerInfoList(newTravelerInfoObj);
  };

  return (
    <div className="mt-[20px] traveller-form">
      {bookingField && traffic && car && (
        <Paper>
          <h5>{t(`kkdayForm.pickUpInfo`)}</h5>
          <ExpamdElementsArrow>
            <Panel showArrow={true} header={t("kkdayForm.expand")} key={0}>
              <div>
                {car["traffic_type"]["is_require"] === "True" && (
                  <div className="w-full mt-5">
                    <label className="block">
                      <span className={labelClass}>
                        {t(`kkdayForm.traffic_type`)}
                      </span>
                      <div className="mt-1">
                        <SelectDropdown
                          showArrow
                          notFoundContent={""}
                          defaultValue={" - "}
                          className={inputClass}
                          handleChange={(e: any) =>
                            handleChange(e, "select", "traffic_type")
                          }
                          options={pickUpTypeList}
                        />
                      </div>
                    </label>
                  </div>
                )}

                {car["s_date"]["is_require"] === "True" && (
                  // Pick Up Date
                  <div className="">
                    <label className="block mt-5">
                      <span className={labelClass}>
                        {t(`kkdayForm.s_date`)}
                      </span>
                      <input
                        disabled
                        type="text"
                        name={`s_date`}
                        value={dateTime}
                        className={inputClass}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                )}

                {car["s_location"]["is_require"] === "True" && (
                  <div className="">
                    <label className="block mt-5">
                      <span className={labelClass}>
                        {t(`kkdayForm.s_location`)}
                      </span>
                      <input
                        type="text"
                        name={`s_location`}
                        className={inputClass}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                )}
              </div>
            </Panel>
          </ExpamdElementsArrow>
        </Paper>
      )}
    </div>
  );
};
