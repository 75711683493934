import { t } from "i18next";
import React from "react";
import { handleHtmlText } from "src/utils/html";

export const KkdayCouponSummary = (props: any) => {
  const { data = {} } = props;
  const { description_module = {} }: any = data;
  return (
    <div className="w-full">
      {Object.keys(description_module).map((descType: string, i: number) => {
        const { content = {} } = description_module[descType];
        const { properties = {} } = content;
        const {
          exchange = {},
          include_item = {},
          cust_reminds = {},
          exchange_type = {},
        } = properties;
        const { desc: how_to_use_desc }: any = exchange_type; //How To Use Voucher

        let descTitle = "";
        let htmlParans: any = {};
        let PMDL: any = descType;

        switch (PMDL) {
          case "PMDL_EXCHANGE": // How to Use Your Voucher
            descTitle = "ticket.exchange_how_to_use";
            htmlParans = how_to_use_desc;
            break;
          case "PMDL_INC_NINC": // Includes/Excludes
            descTitle = "ticket.inc_and_ninc";
            const { list } = include_item;
            htmlParans =
              list.length > 0 &&
              list.map((item: any, i: number) => {
                return item.desc;
              });
            break;
          case "PMDL_REFUND_POLICY": // Cancel Policy (Refund)
            descTitle = "ticket.cancellation_policy";
            const { policy_type } = properties;
            htmlParans = policy_type.desc;
            break;
          case "PMDL_NOTICE": // Reminders
            descTitle = "ticket.reminder";
            const { list: reminder }: any = cust_reminds;
            htmlParans = reminder.map((item: any, i: number) => {
              return item.desc;
            });
            break;
          case "PMDL_HOWTO_SUMMARY": // How To Use
            const { desc: how_to_summary } = content;
            descTitle = "ticket.introduce_summary";
            htmlParans = how_to_summary;
            break;
          case "PMDL_PURCHASE_SUMMARY": // How To Redeem
            const { desc: purchase_summary_desc } = content;
            descTitle = "ticket.purchase_summary";
            htmlParans = purchase_summary_desc;
            break;
          case "PMDL_INTRODUCE_SUMMARY": // Product Info
            const { desc: intro_summary_desc } = content;
            descTitle = "ticket.product_info";
            htmlParans = intro_summary_desc;
            break;
          case "PMDL_EXPERIENCE_LOCATION": // Experience Location
            const { list: content_list } = content;
            descTitle = "ticket.experience_location";
            htmlParans =
              content_list.length > 0 &&
              content_list.map((item: any) => item.latlng?.desc);
            break;
          case "PMDL_USE_VALID": // Validity
            const { desc: valid_desc }: any = exchange;
            descTitle = "ticket.validation";
            htmlParans = valid_desc;
            break;
          case "PMDL_EXCHANGE_VALID": // Redemption Period
            const { desc: ExchangeDesc }: any = exchange;
            descTitle = "ticket.exchange_validation";
            htmlParans = ExchangeDesc;
            break;
          case "PMDL_SCHEDULE":
            descTitle = "ticket.schedule";
            break;
          case "PMDL_GRAPHIC":
          case "PMDL_EXTRA_FEE":
          case "PMDL_NATIONALITY":
          case "PMDL_VENUE_LOCATION":
          case "PMDL_SUGGESTED_ROUTE":
          case "PMDL_EXCHANGE_LOCATION":
          case "PMDL_WIFI":
          case "PMDL_SIM_CARD":
          case "PMDL_PACKAGE_DESC":
            break;
          default:
            htmlParans = null;
            break;
        }
        return (
          <div key={i}>
            <h5 className="mt-2 py-1">{t(descTitle)}</h5>
            <div
              key={i}
              className="text-[10px]"
              dangerouslySetInnerHTML={handleHtmlText(htmlParans)}
            ></div>
          </div>
        );
      })}
    </div>
  );
};
