import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useCouponStore, useMemberStore } from "src/stores";
import { BottomModal } from "../bottomModal";
import { history } from "src/stores";
import {
  openSuccessNotification,
  openWarningNotification,
  openErrNotification,
} from "../notification";
import { LoadingIcon } from "../";

const { Radio } = require("antd");

export const PaymentOptions = (props: any) => {
  const { getUserMembershipInfo } = useMemberStore();
  const [memberInfo, setMemberInfo] = useState<any>({});
  const [, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<string>("at_points");
  const { issueCoupon, issueMezzofyCoupon } = useCouponStore();
  const {
    coupon,
    open,
    setOpen,
    options = [],
    kkdayProps = {},
    myLinkPoint,
  } = props;
  const { couponIcon, couponTitle, atPoints, couponCode, couponId } = coupon;

  const {
    buyerFormValue = {},
    confirmOrderDetails = {},
    contactList = [],
    confirmKkdayOrder,
    cus01List = [],
    cus02List = [],
    dateTime = "",
    eventTime = "",
    formattedSkuList = [],
    formattedSkuNameList = [],
    paymentOrderId = "",
    selectLang = "",
    selectPackage = {},
    isLoading = false,
    setIsLoading = {},
    travelerInfoList = [],
    totalAtPoints,
    totalSalePrice,
  } = kkdayProps;

  useEffect(() => {
    getUserMembershipInfo().then((result) => {
      if (result) return setMemberInfo(result);
    });
  }, [getUserMembershipInfo, setMemberInfo]);

  // Config
  const getPaymentName = (item: string) => {
    switch (item) {
      case "at_points":
        return `AT ${t("points")}`;

      case "myLink_points":
        return `MyLink ${t("points")}`;
    }
  };

  // Get user balance
  const checkBalance = (item: string, balance: string) => {
    switch (item) {
      case "at_points":
        // KKDAY Coupon
        if (coupon.type === "kkday")
          return parseFloat(balance) >= Number(totalAtPoints) ? true : false;
        if (parseFloat(balance) >= parseFloat(coupon["atPoints"])) return true;
    }
    return false;
  };

  const getBalance = (item: string) => {
    switch (item) {
      case "at_points":
        const atPoints = memberInfo["at_points"] || 0;
        return atPoints;

      case "myLink_points":
        const myLink_points = myLinkPoint || 0;
        return myLink_points;
    }
  };

  const getPaymentBtn = (item: string) => {
    const balance = getBalance(item);

    if (item === "myLink_points") {
      return t("exchangePoints");
    }
    const isEnoughBalance = checkBalance(item, balance);
    if (!isEnoughBalance) return t("topup");
    return t("pay");
  };

  const getRequiredAmount = (item: string, balance: string) => {
    switch (item) {
      case "at_points":
        if (coupon.type === "kkday")
          return Number(totalAtPoints - parseFloat(balance));
        return String(parseFloat(coupon["atPoints"]) - parseFloat(balance));
    }
    return 0;
  };

  const purchaseKkdayCoupon = async () => {
    try {
      setIsLoading(true);
      let customList: any = [];
      if (cus01List) customList = [...customList, ...cus01List];
      if (cus02List) customList = [...customList, ...cus02List];
      if (contactList) customList = [...customList, contactList];

      let payload = {
        logo: coupon.couponIcon,
        name: coupon.name,
        item_no: selectPackage.item_no,
        payment_id: confirmOrderDetails.guid,
        order_id: paymentOrderId,
        coupon_id: confirmOrderDetails.prod_no,
        package_no: confirmOrderDetails.pkg_no,
        at_points: totalAtPoints,
        sale_price: totalSalePrice,
        start_date: dateTime,
        end_date: dateTime,
        country: coupon.country,
        buyer_first_name: buyerFormValue.firstName,
        buyer_last_name: buyerFormValue.lastName,
        buyer_email: buyerFormValue.email,
        buyer_tel_country_code: buyerFormValue.countryCode,
        buyer_tel_number: buyerFormValue.mobileNumber,
        ...(eventTime && { event_time: eventTime }),
        guide_lang: selectLang,
        skus: formattedSkuList,
        custom: customList,
        ...(travelerInfoList && {
          traffic: [
            {
              car: {
                ...travelerInfoList,
              },
            },
          ],
        }),
      };

      const confirmedOrder = await confirmKkdayOrder(payload);
      if (!confirmedOrder) {
        setIsLoading(false);
        return openWarningNotification(t("messages.failedToConfirmOrder"));
      }
      const { order_no } = confirmedOrder;
      if (!order_no) {
        setOpen(false);
        setIsLoading(false);
        return openWarningNotification(t("messages.failedToConfirmOrder"));
      }

      const formData = {
        partner_order_id: order_no, // Partner Order No
        at_points: payload["at_points"], // AT Points
        start_date: payload["start_date"], // Select Start
        end_date: payload["end_date"], // Select end date
        total_paid: totalAtPoints, // Pay to AT
        ...(eventTime && { event_time: eventTime }),
        ...(formattedSkuNameList && { sku_name_list: formattedSkuNameList }),
      };

      return history.push("/coupon-purchased", {
        coupon: {
          type: coupon["type"],
          coupon_id: coupon["coupon_id"],
          pkg_name: coupon["pkg_name"],
          couponIcon: coupon["couponIcon"],
        },
        formData,
      });
    } catch (err) {
      return openWarningNotification(t("messages.failedToPurchaseCoupon"));
    }
  };

  const { type } = coupon;
  let kkdayArgs: any = {};

  if (type === "kkday") {
    let customList: any = [];
    if (cus01List) customList = [...customList, ...cus01List];
    if (cus02List) customList = [...customList, ...cus02List];
    if (contactList) customList = [...customList, contactList];

    kkdayArgs = {
      logo: coupon.couponIcon,
      name: coupon.name,
      item_no: selectPackage.item_no,
      payment_id: confirmOrderDetails.guid,
      order_id: paymentOrderId,
      coupon_id: confirmOrderDetails.prod_no,
      package_no: confirmOrderDetails.pkg_no,
      at_points: totalAtPoints,
      sale_price: totalSalePrice,
      start_date: dateTime,
      end_date: dateTime,
      country: coupon.country,
      buyer_first_name: buyerFormValue.firstName,
      buyer_last_name: buyerFormValue.lastName,
      buyer_email: buyerFormValue.email,
      buyer_tel_country_code: buyerFormValue.countryCode,
      buyer_tel_number: buyerFormValue.mobileNumber,
      ...(eventTime && { event_time: eventTime }),
      guide_lang: selectLang,
      skus: formattedSkuList,
      custom: customList,
      ...(travelerInfoList && {
        traffic: [
          {
            car: {
              ...travelerInfoList,
            },
          },
        ],
      }),
    };
  }

  // Submit Payment
  const paymentAction = async (item: string) => {
    const balance = getBalance(item);
    const isEnoughBalance = checkBalance(item, balance);
    const priceDiff = getRequiredAmount("at_points", balance);
    const kkdayExtraData: any = { formattedSkuNameList };

    if (!isEnoughBalance) {
      if (item === "myLink_points") {
        openWarningNotification("messages.mylinkPointInsufficient");
        return history.push("/exchange", { coupon });
      }
      return history.push("/top-up-and-purchase", {
        topUpAmount: priceDiff,
        balance,
        coupon,
        payWith: selectedPayment,
        kkdayArgs,
        kkdayExtraData,
      });
    }

    // Coupon Type (KKDAY)
    if (type === "kkday") {
      try {
        return await purchaseKkdayCoupon();
      } catch (err) {
        return openWarningNotification(t("messages.failedToConfirmOrder"));
      }
    }

    if (type && type === "mezzofy") {
      const isMezzofyCouponIssued = await issueMezzofyCoupon({
        logo: couponIcon,
        name: couponTitle,
        coupon_id: couponId,
        coupon_code: couponCode,
        at_points: atPoints,
        method: "mezzofy",
      });
      if (!isMezzofyCouponIssued) {
        history.push("/");
        return openErrNotification("messages.failedToPurchaseCoupon");
      }
    }
    // Coupon Type (Default)
    const isIssued = await issueCoupon({
      coupon_id: coupon["id"],
      pay_with: selectedPayment,
    });
    if (!isIssued) {
      history.push("/");
      return openErrNotification("messages.failedToPurchaseCoupon");
    }

    setLoading(false);
    openSuccessNotification(t("messages.successfullyPurchasedCoupon"));
    return history.push("/coupon-purchased", { coupon });
  };

  if (!options || options.length === 0) return null;

  return (
    <BottomModal setOpen={setOpen} open={open}>
      <div className="p-5 text-center mx-auto min-h-[300px] flex flex-col">
        <h5 className="pb-[15px]">{t("paymentOptions")}</h5>
        {/* Payment Options */}
        <Radio.Group
          className="flex flex-col flex-1 justify-start"
          value={selectedPayment}
          onChange={(e: any) => setSelectedPayment(e.target.value)}
        >
          {options.map((item: any, i: any) => {
            return (
              <Radio
                className="flex flex-row-reverse pb-3 justify-start items-center"
                value={item}
                key={i}
              >
                <div className="flex flex-row w-[80vw] text-left">
                  <p className="w-[100%]">{getPaymentName(item)}</p>
                  <p className="text-black font-bold mr-3">
                    {getBalance(item)}
                  </p>
                </div>
              </Radio>
            );
          })}
        </Radio.Group>

        {/* Button */}
        <div className="pt-10 flex justify-end">
          <button
            className="primary full py-3"
            onClick={() => paymentAction(selectedPayment)}
          >
            {isLoading ? (
              <div className="flex justify-center items-center">
                <LoadingIcon size={20} color={"white"} />
              </div>
            ) : (
              getPaymentBtn(selectedPayment)
            )}
          </button>
        </div>
      </div>
    </BottomModal>
  );
};
