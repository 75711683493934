import { t } from "i18next";
import React from "react";
import { openWarningNotification } from "src/components";

export const SkusTicketType = (props: any) => {
  const {
    dateTime,
    eventTime,
    confirmOrderDetails,
    skuList,
    setSkuList,
    stockSoldOut,
  } = props;
  const { skus = [], unit = "", unit_quantity_rule } = confirmOrderDetails;
  const { total_rule } = unit_quantity_rule;
  const { max_quantity, min_quantity = 1 } = total_rule;

  const updateSkuList = (
    e: any,
    sku: any,
    increment: boolean,
    coupon: any,
    atPoints: any,
    salePrice: any
  ) => {
    const { couponType, eventTime } = coupon;
    // Check stock level
    if (stockSoldOut)
      return openWarningNotification(t("messages.couponSoldout"));

    if (couponType === "event" && !eventTime)
      return openWarningNotification(t("messages.eventTimeIsMissing"));

    let latestAtPoints = atPoints;
    let latestSalePrice = salePrice;
    const currentQty = skuList.filter(
      (item: any) => item.sku_id === sku.sku_id
    ).length;

    if (!sku) return;
    let newSkuQuantityList: any = [];
    if (increment) {
      // Check if max of "unit_quantity_rule"
      if (currentQty >= max_quantity) {
        openWarningNotification(t("messages.maxQuotaReached"));
        return false;
      }

      const specType =
        sku.specs_ref.length > 0 &&
        sku.specs_ref.map((ref: any) => ref.spec_value_id)[0];

      let newSkuObj = {
        ...sku,
        spec_type: specType,
        latestAtPoints,
        latestSalePrice,
      };
      newSkuQuantityList = [...skuList, newSkuObj];
    }

    if (!increment) {
      if (getSkuQty(sku.sku_id) <= 0) return false; //Disable (-) press if no sku id is selected

      // Check min quantity
      if (skuList.length <= min_quantity)
        openWarningNotification(
          t("messages.minQuotaReached", { minQuantity: min_quantity })
        );
      const idx = skuList.findIndex((item: any) => item.sku_id === sku.sku_id);

      newSkuQuantityList = skuList.filter((item: any, i: number) => i !== idx);
    }
    setSkuList(newSkuQuantityList);
  };

  const getSkuQty = (skuId: string) => {
    if (skuList.length === 0) return 0;
    return skuList.filter((item: any) => item.sku_id === skuId).length;
  };

  return (
    <div className="w-full mt-[10px]">
      <h5 className="text-at-pink">{t("quantityOption")}</h5>
      <div className="w-full">
        {/* SKU Selection */}
        {skus.map((sku: any, i: number) => {
          const { spec_rule, calendar = {}, spec } = sku;
          console.log("sku", sku);
          const { max_age = "", min_age = "" } = spec_rule;

          let latestSalePrice: any = "";
          let latestAtPoints: any = "";
          let couponType = "event";

          let priceOptions =
            calendar && dateTime && calendar[dateTime.toString()];
          if (priceOptions && !priceOptions["b2b_price"]) return null;

          if (priceOptions && priceOptions["b2b_price"]) {
            const isFulldayKey: any = Object.keys(
              priceOptions["b2b_price"]
            ).find((item: any) => item === "fullday");

            if (isFulldayKey === "fullday") {
              couponType = "non-event";
            }
          }

          if (priceOptions && priceOptions["b2b_price"] && eventTime) {
            //Cal lastest coupon's sale price and at points
            latestSalePrice = priceOptions["b2b_price"][eventTime];
            latestAtPoints = priceOptions["b2c_price"][eventTime];
          }

          let selection = Object.values(spec).map((item) => `${item} `);

          return (
            <div
              key={i}
              className="w-full flex flex-row justify-between items-center items-center py-2"
            >
              <div>
                {/* Ticket Type */}
                <div className="flex flex-row justify-center items-center">
                  <p className="text-[14px] text-black">{selection}</p>
                  <p className="ml-2 text-[10px] text-slate-400">
                    {min_age && max_age
                      ? t("ages", { minAge: min_age, maxAge: max_age })
                      : ""}
                  </p>
                </div>

                {/* Ticket Price */}
              </div>
              <div className="flex flex-row items-center">
                <p>{`${latestAtPoints} AT / ${unit}`}</p>
                <p
                  className={`${
                    getSkuQty(sku.sku_id) <= 0 && "cursor-not-allowed"
                  } ml-2 mr-2 cursor-pointer text-[14px] p-[8px] pt-[2px] pb-[2px] border text-at-pink border-at-pink rounded-full`}
                  onClick={(e) =>
                    updateSkuList(
                      e,
                      sku,
                      false,
                      { couponType, eventTime }, //To check event time has selected
                      latestAtPoints,
                      latestSalePrice
                    )
                  }
                >
                  -
                </p>
                <p className="font-bold text-[14px] self-end mr-2 text-center min-w-[40px] text-black p-1">
                  {/* Select Sku Quantity */}
                  {getSkuQty(sku.sku_id)}
                </p>
                <p
                  className={`${
                    !sku
                      ? "text-slate-400 border-slate-400 cursor-not-allowed"
                      : "text-at-pink border-at-pink"
                  } cursor-pointer text-[14px] p-[8px] pt-[2px] pb-[2px] border rounded-full`}
                  onClick={(e) =>
                    updateSkuList(
                      e,
                      sku,
                      true,
                      { couponType, eventTime },
                      latestAtPoints,
                      latestSalePrice
                    )
                  }
                >
                  +
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
