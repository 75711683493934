import { t } from "i18next";
import React, { useState } from "react";
import { DropdownMenu } from "src/components";
import { shoeList } from "src/utils/language";

const labelClass = "block text-sm font-medium text-slate-700";

export const ShoeDropdownSelect = (props: any) => {
  const { bookingField, handleFormChange, name = "" } = props;
  const isShoeRequired =
    bookingField["shoe"] && bookingField["shoe"]["is_require"] === "True";

  const [open, setOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(null);

  const handleMenuClick = (e: any) => {
    if (!e && !e.key) return;
    let value: any = "";

    switch (e.key) {
      case "1-1":
      case "2-1":
      case "3-1":
        value = "01";
        break;
      case "1-2":
      case "2-2":
      case "3-2":
        value = "02";
        break;
      case "1-3":
      case "2-3":
      case "3-3":
        value = "03";
        break;
      default:
        break;
    }

    handleFormChange && handleFormChange(value, "select", name); //For Form Submit
    setDropdownValue(value); //For Display
    setOpen(false);
  };

  const handleVisibleChange = (flag: boolean) => setOpen(flag);

  if (!isShoeRequired) return null;

  return (
    isShoeRequired && (
      <div className="shoe-type">
        <label className="block">
          <span className={labelClass}>{t(`form.shoe`)}</span>
          <DropdownMenu
            open={open}
            dropdownValue={dropdownValue}
            handleMenuClick={handleMenuClick}
            handleVisibleChange={handleVisibleChange}
            className={
              "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 focus:border-primary focus:ring-primary block w-32 rounded-md sm:text-sm focus:ring-1"
            }
            menuItems={shoeList}
          />
        </label>
      </div>
    )
  );
};
