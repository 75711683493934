import { t } from "i18next";
import React from "react";

export const SubmitFormButton = (props: any) => {
  const { handleSubmitButton, data } = props;
  return (
    <div
      className="fixed top-[90%] px-6 w-screen"
      onClick={() => handleSubmitButton(data)}
    >
      <button className="primary full py-3 text-xs">
        {t(`button.bookNow`)}
      </button>
    </div>
  );
};
