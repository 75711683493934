import { t } from "i18next";
import React, { useRef } from "react";
import { Paper, SelectDropdown } from "src/components";
import { DownOutlined } from "@ant-design/icons";
import { PackageOptions } from "./package-options";

const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const GuideLangSelectMenu = (props: any) => {
  const { data, bookingField, guideLanglist, setSelectLang } = props;
  const { atPoints } = data;
  const ref: any = useRef(null);

  const handleChange = (e: any, options: any) => setSelectLang(e);

  const handleSelectClick = () =>
    ref.current?.scrollIntoView({ behavior: "smooth" });

  const isGuidelangRequired =
    bookingField &&
    bookingField["guide_lang"] &&
    bookingField["guide_lang"]["is_require"] === "True";

  return (
    <div className="pt-[20px]">
      {isGuidelangRequired && (
        <Paper className="bg-at-pink">
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center w-full">
              {/* Service language  */}
              <h5>{t(`kkdayForm.lang`)}</h5>
              {/* Guide Language */}
              <div className="ml-[15px] w-40">
                <SelectDropdown
                  showArrow
                  notFoundContent={""}
                  defaultValue={" - "}
                  className={inputClass}
                  handleChange={handleChange}
                  options={guideLanglist}
                />
              </div>
            </div>

            {/* Coupon price (AT points) */}
            <div className="flex flex-col items-start pr-2 mt-[10px]">
              <p className="font-bold text-[16px] text-black">
                {t(`price`, { at_points: atPoints })}
                <span className="font-bold text-[11px] text-slate-400">
                  {t(`upTo`)}
                </span>
              </p>
            </div>

            <div
              className="flex flex-1 flex-row justify-center items-center w-full mt-[10px]"
              ref={ref}
            >
              <button
                className="py-3 pr-3 text-xs secondary !border-none"
                onClick={() => handleSelectClick()}
              >
                {t("button.packageOptions")}
              </button>
              <div className="  package-option">
                <DownOutlined />
              </div>
            </div>
          </div>
        </Paper>
      )}

      {/* Expand Package Options */}
      <div className="pt-[20px]" ref={ref}>
        <Paper>
          <h5>{t("button.packageOptions")}</h5>
          <PackageOptions {...props} />
        </Paper>
      </div>
    </div>
  );
};
