import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, FullTicket, LoadingIcon } from "src/components";
import { history, useMyLinkStore, useCouponStore } from "src/stores";
import { GetToken } from "src/utils/token";

export const CouponDetail = (props: any) => {
  const { location } = props;

  const { t } = useTranslation();
  const [myLinkPoint, setMyLinkPoint] = useState(-1);

  const { getMyLinkUserPoints } = useMyLinkStore();
  const { getMezzofyCoupon, getKkdayCoupon } = useCouponStore();
  const from = (location.state && location.state.from) || "";
  const type = (location.state && location.state.type) || "";
  const coupon = (location.state && location.state.coupon) || {};

  const [data, setData] = useState<any>({});
  const [loading, setIsLoading] = useState<boolean>(true);

  const toPage = () => {
    switch (from) {
      case "myCoupon":
        return "/my-coupons";
      default:
        return "/coupons-home";
    }
  };

  useEffect(() => {
    if (!coupon) return history.push("/coupons-home");
    if (!coupon.type) {
      setData(coupon);
      setIsLoading(false);
    }

    let type = coupon?.type;
    let couponId = coupon?.couponId;
    // KKDAY Coupon Details Page
    if (type === "kkday") {
      getKkdayCoupon(`?coupon_id=${couponId}`).then((result) => {
        setIsLoading(false);
        if (!result) return;
        const newCouponObj = {
          ...coupon,
          ...result,
        };
        return setData({ ...newCouponObj });
      });
    }

    // AT Coupons (Without Type)
    if (!type || type !== "kkday") {
      setIsLoading(false);
      return setData(coupon);
    }

    if (coupon.type && coupon.type === "mezzofy" && coupon.couponId) {
      getMezzofyCoupon(coupon.couponId).then((result: any) => {
        setIsLoading(false);
        if (!result) return;
        let { available_outlets = [] } = result;
        let newCouponData: any = {
          ...coupon,
          availableOutlets: available_outlets, // update available outlets from coupon mezzofy
        };
        result && setData(newCouponData);
      });
    }
  }, [coupon, setData, getMezzofyCoupon, getKkdayCoupon, setIsLoading]);

  // call my link
  useEffect(() => {
    const initMyLinkData = async () => {
      const token = GetToken("my-link-token");
      let myLinkPoint: any;
      if (token) {
        let result: any = await getMyLinkUserPoints(token);
        myLinkPoint = result.userPointAvailable;
        setMyLinkPoint(myLinkPoint);
      }
    };
    initMyLinkData();
  }, [getMyLinkUserPoints]);

  return (
    <Container
      from="couponDetail"
      toPage={toPage()}
      title={`${t("coupon")}${t("detail")}`}
      showExchange={true}
    >
      {loading && (
        <div className="flex justify-center flex-1 py-5">
          <LoadingIcon />
        </div>
      )}

      {!loading && (
        <div className="px-5 py-5">
          {data && (
            <FullTicket
              data={data}
              from={from}
              type={type}
              myLinkPoint={myLinkPoint}
            />
          )}
        </div>
      )}
    </Container>
  );
};
