export const scTranslation = {
  changeLang: "Change Language",
  en: "English (en)",
  "zh-tw": "简体中文 (zh-tw)",
  "zh-cn": "简体中文 (zh-cn)",
  "zh-hk": "繁體中文 (zh-hk)",
  female: "女性",
  male: "男性",
  explore: "探索",
  profile: "会员专区",
  debitCard: "金融卡",
  wallet: "钱包",
  service: "服务",
  exclusiveEvents: "独家活动",
  trendingCoupons: "热门优惠券",
  newMovies: "最新电影",
  all: "所有",
  "kkday-coupon": "优惠券",
  coupon: "优惠券",
  detail: "详情",
  purchased: "已购买",
  expiration: "到期日",
  validePeriod: "有效期限",
  membershipBenefit: "黑曜专区",
  myPoints: "我的积分",
  myCoupons: "我的优惠券",
  myReferralCode: "我的推荐码",
  settings: "编辑个人",
  referralProgram: "推荐计划",
  newMemberEvents: "新人活动",
  memberEvents: "会员活动",
  exchangePoints: "积分换兑",
  exchangeRate: "最新兑换率 1 HKD : 1 AT",
  languages: "语言",
  logout: "登出",
  cs: "官方客服",
  email: "电邮地址",
  phone: "电话号码",
  workingTimes: "工作时间",
  wokringTimeDetailOne: "星期一至周五（9：30-17：00）。",
  wokringTimeDetailTwo: "在星期六，周日和公共假期关闭。",
  backUp: "返回",
  from: "从",
  to: "到",
  or: "或",
  use: "使用",
  paymentOptions: "付款选项",
  pay: "付款",
  payme: "Payme",
  topup: "购买",
  confirmTopup: "确认充值",
  topUpDone: "充值成功",
  topUpSuccess: "已成功充值！",
  topupNow: "立即充值",
  topupAtpoints: "充值 AT",
  topupAmount: "充值金额 (HKD)",
  topupMethod: "充值方式",
  orderNumber: "订单号码",
  doNotRefresh: "付款后请勿刷新页面...",
  atPoints: "AT 积分",
  points: "积分",
  requiredTopUpPoints: "需要 AT 积分",
  setPin: "设置您的 6 位付款密码",
  otp: "请输入验证码",
  pin: "请输入您想设置的付款密码",
  noCouponFound: "暂时查无优惠券。",
  noMovieFound: "暂时查无电影。",
  checkOut: "查看",
  movieShowTimes: "查看电影时间",
  buyTicket: "前往购票",
  moviePrice: "票价",
  allVersions: "所有版本",
  noMovieShowingTime: "暂无电影场次",
  minute: "分钟",
  clickToCopy: "点击以复制兑换码",
  confirmIsUsed: "确认此兑换券已经换兑吗？",
  used: "已使用",
  exchangeNow: "立即轉換",
  exchangeRateTxt: "兌換率",
  confirmExchange: "確認轉換",
  notSelectExchangeItems: "请先选择转换项目",
  exchangeTitle: "积分转换",
  exchangeSuccess: "兑换成功",
  exchangeFailed: "兑换失败，请稍后重试！",
  referral: {
    title: "我的推荐码",
    form: {
      referralCode: "邀请人推荐码",
      codeConfirm: "确定",
      success: "邀请码使用成功",
      error: {
        empty: "邀请码不可空",
        self: "不可使用自己的邀请码",
      },
      used: "你已使用此邀请码",
    },
    errors: {
      default: "该邀请码无效！",
      used: "你已使用邀请码！",
    },
    copied: "成功复制",
    subtitle: "推荐福利",
    promo1: "关注我们以获得最新优惠",
    promo2: "邀请朋友购买优惠券，即可获得 AT Rewards 积分",
  },
  services: {
    available: "服務餘額",
    toByonOpen: "前往 BYON 開通",
    byonChecking: "BYON 審批中",
    byonApproved: "更多 BYON 金額",
    availableMerchants: "可用商戶",
    openService: "申請 byonPAYLATER {{type}} 服務",
    paymentDesc: "还款假期后需每月还款",
    paymentNote33:
      "三个月还款假期： 享有首三个月免息还款假期，之后分三期 (3+3) 还款",
    paymentNote66:
      "六个月还款假期： 享有首六个月免息还款假期，之后分六期 (6+6) 还款",
    extraNote:
      "*以上还款金额以每月平息 0.39% 计算，表内所显示的还款金额以及期限仅供参考。 ",
    serviceTitle: "「{{type}}」还款假期",
    serviceDesc: {
      at33: "指定商户可享有首三个月免息免供还款假期，后三个月以低至每月平息0.15%还款，消费更轻松！",
      at66: "指定商户可享有首六个月免息免供还款假期，后六个月以低至每月平息0.15%还款，轻松还款！",
    },
    disclaimer1: "欧力士财务服务香港有限公司旗下品牌",
    disclaimer2: "放债人牌照号码: 1813/2022",
    disclaimer3: "忠告：借钱梗要还， 咪俾钱中介",
    disclaimer4: "客户服务／投诉热线： (852) 2862 9119",
    topup: "充值",
    activate: "获取专款专用卡",
    cardInfo: "卡号详情",
    phoneSet: "电话号码设置",
    pinSet: "六位 PIN 设置",
    verification: "身份认证",
    toVerify: "前往身份认证",
    scanToPay: "扫码付款",
    verifyNote:
      "请使用电脑访问 https://www.globalcash.cn/v4/activate/index 进行登录并完成身份验证。",
  },
  ticket: {
    place: "适用地点",
    hk: "香港",
    howToUse: "如何使用",
    exchange_how_to_use: "凭证使用方式",
    inc_and_ninc: "费用包含 / 不包含",
    cancellation_policy: "取消政策描述",
    introduce_summary: "如何使用",
    purchase_summary: "购买须知摘要",
    product_info: "商品说明",
    experience_location: "体验地点",
    validation: "使用期限",
    exchange_validation: "凭证兑换期限",
    schedule: "多日遊行程",
    tnc: "条款与细则",
    get: "立即获取",
    use: "立即使用",
    couponInfo: "產品信息",
    reminder: "注意",
  },
  button: {
    returnToWallet: "返回钱包",
    returnToMyPoints: "返回我的积分",
    continueExploring: "继续浏览",
    editProfile: "更新资料",
    havePaid: "我已付款",
    paidWithPayme: "开启 Payme 付款",
    paidWithOctopus: "前往 Octopus 付款",
    soldOut: "已售罄",
    confirm: "确定",
    cancel: "取消",
    showQrCode: "展示二维码",
    goToMintingPage: "探索 AT Birds 并成为黑曜石会员",
    showAtBird: "查看",
    kkday: "KKDAY 優惠券",
    filter: "类别筛选",
    close: "關閉",
    search: "搜寻",
    select: "选择",
    selected: "已选择",
    packageOptions: "选择套餐",
    cancelPackageOptions: "取消选项",
    viewCouponDetail: "查看优惠券详情",
    bookNow: "立即订购",
    download: "下载优惠券详情",
    payNow: "前往付款",
    goToTopup: "去充值",
    octopus: "八达通付款",
    back: "返回",
  },
  myNftCollection: "所有 NFT 收藏品",
  nft: {
    noWalletAddress: "找不到您的钱包地址",
    myCollectionHeader: "AT BIRD 收藏品",
    allCollections: "所有 NFT 收藏品",
    displayDummyNftHeader: "AsiaTop NFT 收藏品",
    outstandingDiscount: "持有优惠",
    copied: "",
    confirm: "确定",
    cancel: "取消",
    "AT Birds": {
      desc1: "持有 AT Bird 升级为黑曜石会员",
      desc2: "更多优惠即将公布",
    },
  },
  card: {
    tier: "会员阶级",
    graz: "欢迎！",
    jade: "Jade",
    GOLD: "Gold",
    DIAMOND: "Diamond",
    OBSIDIAN: "OBSIDIAN",
    stat: "让我们享受这些精彩优惠吧！",
  },
  messages: {
    pleaseWait: "敬请期待",
    comingSoon: "快将推出。",
    congraz: "恭喜",
    warning: "稍等一下",
    error: "抱歉",
    getCodeNoFieldErr: "请输入您的区号及电话号码。",
    sendOtpFail: "暂时无法发出认证码，请确认您的区号及电话号码。",
    sendOtpSuccess: "我们已把认证码发送到您的号码，此认证码可维持五分钟。",
    verifyOtpFail: "暂时无法认证认证码，请确认您的认证码、区号及电话号码。",
    loginSuccess: "已成功登入。",
    pleaseEnterTopupAmount: "请输入充值金額",
    createPaymentFailed: "建立付款失败",
    updateUserFail: "暂时未能更新您的个人资料。",
    updateUserSuccess: "您的个人资料已经更新。",
    noCoupon: "暂时没有可用的优惠券。",
    approveOrderErr: "暂时无法处理您的充值，请联络我们客服。",
    mylinkPointInsufficient: "请先换取AT Points",
    confirmPayment: "请确认您已付款。",
    paymentExpired: "付款码已过期，请重新尝试。",
    couponSoldout: "优惠券已售罄。",
    obsidianLevelRequired: "您的会员等级不足，请考虑升级至黑跃石会员",
    failedToUserInactive: "你的账号已被封锁，请联系客服解封",
    failedToVerifyUser: "無法驗證用户",
    logout: "已成功出登。",
    otpIncorrect: "OTP不正确，请重新输入。",
    failedToUpdatePaymentOrder: "更新订单失败",
    incorrectEmailFormat: "请输入有效邮件",
    incorrectMobileNumber: "请输入电话号码",
    emailMissing: "请填写电邮地址",
    firstNameMissing: "请填写名",
    lastNameMissing: "请填写姓",
    countryCodeMissing: "请填写国家地区代码",
    mobileNumberMissing: "请填写电话号码",
    guideLanguageMissing: "请选择服务语言",
    eventTimeIsMissing: "请选择时间",
    maxQuotaReached: "超出订购数量限制",
    minQuotaReached: "最小购买数量是 {{minQuantity}}",
    noVoucherFileFound: "此优惠券没有档案",
    requiredFieldMissing: "未完成必填项目",
    dateTimeMissing: "请选择日子",
    packageMissing: "请选择套餐",
    invalidDateTimeSelected: "选择日子無效",
    failedToSelectPackage: "选择套餐失败",
    packageNotSelected: "請选择套餐",
    failedToConfirmOrder: "确认单失败",
    failedToCreatePaymentOrder: "建立訂单付款失败",
    failedToDownloadVoucher: "下载优惠券失败",
    failedToRedeemCoupon: "换领优惠券失败",
    failedToPurchaseCoupon: "购买优惠券失败",
    nft_or_tier_required: "会员等级不足或未持有特定 NFT",
    disclaimer: "请先同意免责声明才能成为 AT Rewards 会员",
    successfullyPurchasedCoupon: "成功购买优惠券。",
    redeemedCouponSuccess: "成功兑换优惠券。",
  },
  disclaimer: {
    myLink:
      "点击 “我同意”即表示您同意我们的服务条款并同意成为 AT Rewards 会员。",
    agree: "我同意",
    discard: "不同意",
  },
  form: {
    name: "昵称",
    email: "电邮地址",
    dob: "出生日期",
    submit: "提交",
    save: "保存资料",
    summary: "产品概要",
    countryName: "区号",
    phone: "电话号码",
    code: "验证码",
    register: "登录",
    gender: "姓別",
    getCode: "获取认证码",
    enter: "确认",
    inviterCode: "邀请人推荐码",
  },
  kkdayForm: {
    lang: "服务语言",
    s_date: "出发日 (YYYY-MM-DD)*",
    s_location: "套餐编号下需填入的交通起始地址*",
    e_location: "套餐编号下需填入的交通结束地址*",
    buyerInfo: "买家资料",
    travellerInfo: "游客资料",
    pickUpInfo: "接送资料*",
    gender: "姓別*",
    contactInfo: "联络人资料",
    travellerFirstName: "名 (与护照相同)*",
    travellerLastName: "姓 (与护照相同)*",
    native_first_name: "中文名 (与护照相同)*",
    native_last_name: "中文姓 (与护照相同)*",
    english_first_name: "英文名 (与护照相同)*",
    english_last_name: "英文姓 (与护照相同)*",
    tel_number: "電話號碼 (遊客)*",
    MTP_no: "台胞证号 (仅限台湾人))*",
    id_no: "身分证号*",
    passport_no: "护照号码*",
    passport_expdate: "护照到期日 (YYYY-MM-DD)*",
    birth: "生日 (YYYY-MM-DD)*",
    height: "身高*",
    height_unit: "身高单位*",
    weight: "体重*",
    weight_unit: "体重单位*",
    shoe: "鞋子*",
    shoe_unit: "鞋子尺寸*",
    shoe_type: "鞋子类型*",
    glass_degree: "眼镜度数*",
    meal: "餐食*",
    allergy_food: "过敏食物*",
    have_app: "是否有App资讯*",
    contact_app: "提供通讯软体*",
    contact_app_account: "通讯软体的帐号*",
    zipcode: "邮递区号*",
    address: "地址*",
    guide_lang: "服务语言*",
    firstName: "名 (买家)*",
    lastName: "姓 (买家)*",
    contactFirstName: "名*",
    contactLastName: "姓*",
    nationality: "国籍*",
    email: "电子邮件 (买家)*",
    mobile: "电话号码 (买家)*",
    orderNumber: "您的订单号码",
    specialRequirement: "特殊需求备注",
    selectTime: "选择时间",
    selectDateTime: "选择日子",
    selectedDateTime: "已选择日子：",
    expand: "展开这里填写资料",
    soldOut: "你所选的时间已售罄，请重选其他日子",
    traffic_type: "套餐编号下需填入的交通种类*",
    disclaimer: "以上邮箱和电话号码将作为订单相关事宜的联络方式",
    traveler: "人",
    adult: "成人",
    child: "儿童",
    teenager: "青少年",
    senior: "长者",
    men: "先生",
    women: "女士",
    default: "人数",
  },
  point: {
    pointNumber: "积分数量",
    enter: "确认",
  },
  screeningsType: {
    allTheaterLines: "所有院线",
    allRegions: "所有地区",
    allVersions: "所有版本",
  },
  events: {
    nft: "<span>成为黑曜石会员，尽享高达30%的优惠！</span>",
    drink:
      "<span>前往 𝘼𝙏 𝙍𝙚𝙬𝙖𝙧𝙙𝙨 幸运抽奖！奶茶咖啡饮到饱！<br><br>#𝘼𝙏 𝙍𝙚𝙬𝙖𝙧𝙙𝙨 𝙇𝙪𝙘𝙠𝙮 𝘿𝙧𝙖𝙬 </span>",
    atghnft: "<span>AT BIRD「不得鸟」NFT传送门</span>",
    nftflash: "「不得鸟」NFT会员 #快闪专属优惠",
    maysale: "AT Bird五月快闪优惠，最低五折",
  },
  modal: {
    pendingTransaction: "待处理交易",
    pendingTransactionDone: "已完成交易",
    pendingTransactionAmount:
      "您有一项代处理充值交易金额为 {{amount}} AT。您要继续完成吗？",
    pendingTransactionCompleted: "您的充值交易处理已完成，金额为 {{amount}} AT",
  },
  redeemNow: "前往换兑",
  exchange: {
    exchangeAmount: "转换金額",
    error: {
      ATPBalanceInsufficient: "余额不足",
      empty: "转换金額不能为空",
      emptyExchangeOption: "请先选择转换积分",
    },
    selectionTitle: "选择从哪一种积分转换",
    exchangedFailed: "请稍后再尝试",
    noExchangeOption: "你的帐户暂时没有转换选项",
  },
  atpTitle: "聚分俠 ATP",
  earliestAvailableDate: "最早可预订日：",
  packageOptions: "套餐选项",
  quantityOption: "选择数量",
  timeOption: "选择时间",
  orderSummary: "订单摘要",
  orderTotal: "订单总额",
  totalPaid: "总金额",
  paidAmount: "支付金额",
  productNo: "产品编号: {{prodNo}}",
  price: "{{at_points}} AT ",
  upTo: "起",
  ages: "({{minAge}} - {{maxAge}} 岁)",
};
