export const tcTranslation = {
  changeLang: "Change Language",
  en: "English (en)",
  "zh-tw": "简体中文 (zh-tw)",
  "zh-cn": "简体中文 (zh-cn)",
  "zh-hk": "繁體中文 (zh-hk)",
  female: "女性",
  male: "男性",
  explore: "探索",
  profile: "會員專區",
  debitCard: "金融卡",
  wallet: "錢包",
  service: "服務",
  exclusiveEvents: "獨家活動",
  trendingCoupons: "熱門優惠券",
  newMovies: "最新電影",
  all: "所有",
  coupon: "優惠券",
  "kkday-coupon": "KKDAY 優惠券",
  detail: "詳情",
  purchased: "已購買",
  expiration: "到期日",
  validePeriod: "有效期限",
  membershipBenefit: "黑曜專區",
  myPoints: "我的積分",
  myCoupons: "我的優惠券",
  myReferralCode: "我的推薦碼",
  settings: "編輯個人",
  referralProgram: "推薦計劃",
  newMemberEvents: "新人活動",
  memberEvents: "會員活動",
  exchangePoints: "積分換兌",
  exchangeRate: "最新兑换率 1 HKD : 1 AT",
  languages: "語言",
  logout: "登出",
  cs: "官方客服",
  email: "電郵地址",
  phone: "電話號碼",
  workingTimes: "工作時間",
  wokringTimeDetailOne: "星期一至週五（9：30-17：00）。",
  wokringTimeDetailTwo: "在星期六，週日和公共假期關閉。",
  backUp: "返回",
  from: "從",
  to: "到",
  or: "或",
  use: "使用",
  paymentOptions: "付款選項",
  pay: "付款",
  payme: "Payme",
  topup: "購買",
  confirmTopup: "確認充值",
  topUpDone: "充值成功",
  topUpSuccess: "已成功充值！",
  topupNow: "立即充值",
  topupAtpoints: "充值 AT",
  topupAmount: "充值金額 (HKD)",
  topupMethod: "充值方式",
  orderNumber: "訂單號碼",
  doNotRefresh: "付款後請勿刷新頁面...",
  atPoints: "AT 積分",
  points: "積分",
  requiredTopUpPoints: "需要 AT 積分",
  setPin: "設置您的 6 位付款密碼",
  otp: "請輸入驗證碼",
  pin: "請輸入您想設置的付款密碼",
  noCouponFound: "暫時查無優惠券。",
  noMovieFound: "暫時查無電影。",
  checkOut: "查看",
  movieShowTimes: "查看電影時間",
  buyTicket: "前往購票",
  moviePrice: "票價",
  allVersions: "所有版本",
  noMovieShowingTime: "暫無電影場次",
  minute: "分鐘",
  clickToCopy: "點擊以複製兌換碼",
  confirmIsUsed: "確認此兌換券已經換兌嗎？",
  used: "已使用",
  exchangeNow: "立即轉換",
  exchangeRateTxt: "兌換率",
  confirmExchange: "確認轉換",
  notSelectExchangeItems: "請先選擇轉換項目",
  exchangeTitle: "積分轉換",
  exchangeSuccess: "兌換成功",
  exchangeFailed: "兌換失敗，請稍後重試！",
  referral: {
    title: "我的推薦碼",
    form: {
      referralCode: "邀請人推薦碼",
      codeConfirm: "確定",
      success: "邀請碼使用成功",
      error: {
        empty: "邀請碼不可空",
        self: "不可使用自己的邀請碼",
      },
      used: "你已使用此邀請碼",
    },
    errors: {
      default: "該邀請碼無效！",
      used: "你已使用邀請碼！",
    },
    copied: "成功複制",
    subtitle: "推薦福利",
    promo1: "關注我們以獲得最新優惠",
    promo2: "邀請朋友購買優惠券，即可獲得 AT Rewards 積分",
  },
  services: {
    available: "餘額",
    toByonOpen: "前往 BYON 開通",
    byonChecking: "BYON 審批中",
    byonApproved: "更多 BYON 金額",
    availableMerchants: "可用商戶",
    openService: "申請 byonPAYLATER {{type}} 服務",
    paymentDesc: "還款假期後需每月還款",
    paymentNote33:
      "三個月還款假期： 享有首三個月免息還款假期，之後分三期 (3+3) 還款",
    paymentNote66:
      "六個月還款假期： 享有首六個月免息還款假期，之後分六期 (6+6) 還款",
    extraNote:
      "*以上還款金額以每月平息 0.39% 計算，表內所顯示的還款金額以及期限僅供參考。",
    serviceTitle: "「{{type}}」還款假期",
    serviceDesc: {
      at33: "指定商戶可享有首三個月免息免供還款假期，後三個月以低至每月平息0.15%還款，消費更輕鬆！",
      at66: "指定商戶可享有首六個月免息免供還款假期，後六個月以低至每月平息0.15%還款，輕鬆還款！",
    },
    disclaimer1: "歐力士財務服務香港有限公司旗下品牌",
    disclaimer2: "放債人牌照號碼: 1813/2022",
    disclaimer3: "忠告：借錢梗要還， 咪俾錢中介",
    disclaimer4: "客戶服務／投訴熱線： (852) 2862 9119",
    topup: "充值",
    activate: "獲取專款專用卡",
    cardInfo: "卡號詳情",
    phoneSet: "電話號碼設置",
    pinSet: "六位 PIN 設置",
    verification: "身份認證",
    toVerify: "前往身份認證",
    scanToPay: "掃碼付款",
    verifyNote:
      "請以電腦瀏覽 https://www.globalcash.cn/v4/activate/index 以登入並進行身份認證。",
  },
  ticket: {
    place: "適用地點",
    hk: "香港",
    howToUse: "如何使用",
    exchange_how_to_use: "憑證使用方式",
    inc_and_ninc: "費用包含 / 不包含",
    cancellation_policy: "取消政策描述",
    introduce_summary: "如何使用",
    purchase_summary: "購買須知摘要",
    product_info: "商品說明",
    experience_location: "體驗地點",
    validation: "使用期限",
    exchange_validation: "憑證兌換期限",
    schedule: "行程介紹",
    tnc: "條款與細則",
    get: "立即獲取",
    use: "立即使用",
    couponInfo: "產品信息",
    reminder: "注意",
  },
  button: {
    returnToWallet: "返回錢包",
    returnToMyPoints: "返回我的積分",
    continueExploring: "繼續瀏覽",
    editProfile: "更新資料",
    havePaid: "我已付款",
    paidWithPayme: "開啟 Payme 付款",
    paidWithOctopus: "前往 Octopus 付款",
    soldOut: "已售罄",
    loading: "讀取資料中⋯",
    setPin: "設定付款碼",
    getOtp: "獲取驗證碼",
    nftCollection: "我的 NFT 收藏品",
    showQrCode: "展示二維碼",
    goToMintingPage: "探索 AT Birds 並成為黑曜石會員",
    showAtBird: "查看",
    copy: "",
    close: "關閉",
    confirm: "確認",
    cancel: "取消",
    filter: "類別篩選",
    kkday: "KKDAY 優惠券",
    search: "搜尋",
    select: "選擇",
    selected: "已選擇",
    packageOptions: "套餐選項",
    cancelPackageOptions: "取消選項",
    viewCouponDetail: "查看優惠券詳情",
    bookNow: "立即訂購",
    download: "下載優惠券詳情",
    payNow: "前往付款",
    goToTopup: "去充值",
    octopus: "八達通付款",
    back: "返回",
  },
  myNftCollection: "所有 NFT 收藏品",
  nft: {
    noWalletAddress: "找不到您的錢包地址",
    displayDummyNftHeader: "AsiaTop NFT 收藏品",
    myCollectionHeader: "AT BIRD 收藏品",
    allCollections: "所有 NFT 收藏品",
    outstandingDiscount: "持有優惠",
    copied: "",
    confirm: "確定",
    cancel: "取消",
    "AT Birds": {
      desc1: "持有 AT Bird 升級為黑曜石會員",
      desc2: "更多優惠即將公佈",
    },
  },
  card: {
    tier: "會員階級",
    graz: "歡迎！",
    jade: "Jade",
    GOLD: "Gold",
    DIAMOND: "Diamond",
    OBSIDIAN: "OBSIDIAN",
    stat: "讓我們享受這些精彩優惠吧！",
  },
  messages: {
    pleaseWait: "敬請期待",
    comingSoon: "快將推出。",
    congraz: "恭喜",
    warning: "稍等一下",
    error: "抱歉",
    getCodeNoFieldErr: "請輸入您的區號及電話號碼。",
    sendOtpFail: "暫時無法發出認證碼，請確認您的區號及電話號碼。",
    sendOtpSuccess: "我們已把認證碼發送到您的號碼，此認證碼可維持五分鐘。",
    verifyOtpFail: "暫時無法認證認證碼，請確認您的認證碼、區號及電話號碼。",
    loginSuccess: "已成功登入。",
    pleaseEnterTopupAmount: "請輸入充值金額",
    createPaymentFailed: "建立付款失敗",
    updateUserFail: "暫時未能更新您的個人資料。",
    updateUserSuccess: "您的個人資料已經更新。",
    noCoupon: "暫時沒有可用的優惠券。",
    approveOrderErr: "暫時無法處理您的充值，請聯絡我們客服。",
    mylinkPointInsufficient: "請先換取 AT Points",
    confirmPayment: "請確認您已付款。",
    paymentExpired: "付款碼已過期，請重新嘗試。",
    couponSoldout: "優惠券已售罄。",
    formNotReady: "請先提交 BYON 表格。",
    cardNotReady: "正在準備您的錢包。",
    tryAgain: "請重新嘗試。",
    requireOtp: "請輸入六位驗證碼。",
    requirePwd: "請輸入六位支付密碼。",
    pinSet: "已設置付款密碼，請選擇服務。",
    formNotApproved: "BYON 表格正在審批中。",
    merchantNotSame: "此二維碼屬於別的商戶。",
    cannotPay: "暫時無法付款，請重新嘗試。",
    obsidianLevelRequired: "您的會員等級不足，請考慮升級至黑躍石會員",
    failedToUserInactive: "你的帳號已被封鎖，請聯絡客服解封",
    failedToVerifyUser: "無法驗證用户",
    logout: "已成功登出。",
    otpIncorrect: "OTP不正確，請重新輸入。",
    failedToUpdatePaymentOrder: "更新訂單失敗",
    incorrectEmailFormat: "請輸入有效電郵",
    incorrectMobileNumber: "請輸入電話號碼",
    emailMissing: "請填寫電郵地址",
    firstNameMissing: "请填寫名",
    lastNameMissing: "请填寫姓",
    countryCodeMissing: "请填寫國家地區代碼",
    mobileNumberMissing: "请填寫電話號碼",
    guideLanguageMissing: "請選擇服務語言",
    eventTimeIsMissing: "請選擇時間",
    maxQuotaReached: "超出訂購數量限制",
    minQuotaReached: "最小購買數量是 {{minQuantity}} ",
    noVoucherFileFound: "此優惠券沒有檔案",
    requiredFieldMissing: "未完成必填項目",
    dateTimeMissing: "請選擇日子",
    packageMissing: "請選擇套餐",
    invalidDateTimeSelected: "選擇日子無效",
    failedToSelectPackage: "選擇套餐失敗",
    packageNotSelected: "請選擇套餐",
    failedToConfirmOrder: "確認訂單失敗",
    failedToConfirmPaymentOrder: "確認訂單付款失敗",
    failedToCreateOrder: "建立訂單失敗",
    failedToDownloadVoucher: "下載優惠券失敗",
    failedToRedeemCoupon: "換領優惠券失敗",
    failedToPurchaseCoupon: "購買優惠券失敗",
    nft_or_tier_required: "會員等級不足或未持有特定 NFT",
    disclaimer: "請先同意免責聲明才能成為 AT Rewards 會員",
    successfullyPurchasedCoupon: "成功購買優惠券。",
    redeemedCouponSuccess: "成功兌換優惠券。",
  },
  disclaimer: {
    myLink:
      "點擊“我同意”即表示您同意我們的服務條款並同意成為 AT Rewards 會員。",
    agree: "我同意",
    discard: "不同意",
  },
  form: {
    name: "暱稱",
    email: "電郵地址",
    dob: "出生日期",
    submit: "提交",
    save: "保存資料",
    summary: "產品概要",
    countryName: "區號",
    phone: "電話號碼",
    code: "驗證碼",
    register: "登錄",
    gender: "姓別",
    getCode: "獲取認證碼",
    enter: "確認",
    inviterCode: "邀請人推薦碼",
  },
  kkdayForm: {
    lang: "服務語言",
    s_date: "出發日 (YYYY-MM-DD)*",
    s_location: "套餐編號下需填入的交通起始地址*",
    e_location: "套餐編號下需填入的交通結束地址*",
    buyerInfo: "買家資料",
    travellerInfo: "遊客資料",
    pickUpInfo: "接送資料*",
    gender: "姓別*",
    contactInfo: "聯絡人資料",
    travellerFirstName: "名 (遊客)*",
    travellerLastName: "姓 (遊客)*",
    native_first_name: "中文名 (與護照相同)*",
    native_last_name: "中文姓 (與護照相同)*",
    english_first_name: "英文名 (與護照相同)*",
    english_last_name: "英文姓 (與護照相同)*",
    tel_number: "電話號碼 (遊客)*",
    MTP_no: "台胞證號 (僅限台灣人)*",
    id_no: "身分證號*",
    passport_no: "護照號碼*",
    passport_expdate: "護照到期日 (YYYY-MM-DD)*",
    birth: "生日 (YYYY-MM-DD)*",
    height: "身高*",
    height_unit: "身高單位*",
    weight: "體重*",
    weight_unit: "體重單位*",
    shoe: "鞋子尺寸*",
    shoe_unit: "鞋子單位*",
    shoe_type: "鞋子類型*",
    glass_degree: "眼鏡度數*",
    meal: "餐食*",
    allergy_food: "過敏食物*",
    have_app: "是否有App資訊*",
    contact_app: "提供通訊軟體*",
    contact_app_account: "通訊軟體的帳號*",
    zipcode: "郵遞區號*",
    address: "地址*",
    guide_lang: "服務語言",
    firstName: "名 (買家)*",
    lastName: "姓 (買家)*",
    contactFirstName: "名*",
    contactLastName: "姓*",
    nationality: "國籍",
    email: "電子郵件 (買家)*",
    mobile: "電話號碼 (買家)*",
    orderNumber: "您的訂單號碼",
    specialRequirement: "特殊需求備註",
    selectTime: "選擇時間",
    selectDateTime: "選擇日子",
    selectedDateTime: "已選擇日子：",
    expand: "展開這裏填寫資料",
    soldOut: "你所選的時間已售罄，請重選其他日子",
    disclaimer: "以上郵箱和電話號碼將作為訂單相關事宜的聯絡方式",
    traveler: "人",
    adult: "成人",
    child: "兒童",
    teenager: "青少年",
    senior: "長者",
    men: "先生",
    women: "女士",
    traffic_type: "套餐編號下需填入的交通種類*",
    default: "人數",
  },
  point: {
    pointNumber: "積分數量",
    enter: "確認",
  },
  screeningsType: {
    allTheaterLines: "所有院線",
    allRegions: "所有地區",
    allVersions: "所有版本",
  },
  events: {
    nft: "<span>成為黑曜石會員，盡享高達30%的優惠！</span>",
    drink:
      "<span>前往 𝘼𝙏 𝙍𝙚𝙬𝙖𝙧𝙙𝙨 幸運抽獎！奶茶咖啡飲到飽！<br><br>#𝘼𝙏 𝙍𝙚𝙬𝙖𝙧𝙙𝙨 𝙇𝙪𝙘𝙠𝙮 𝘿𝙧𝙖𝙬 </span>",
    atghnft: "<span>#四月玩大佢<br><br>AT BIRD「不得鳥」NFT傳送門</span>",
    nftflash: "「不得鳥」NFT會員 #快閃專屬優惠",
  },
  modal: {
    pendingTransaction: "待處理交易",
    pendingTransactionDone: "已完成交易",
    pendingTransactionAmount:
      "您有一項代處理充值交易金額為 {{amount}} AT。您要繼續完成嗎？",
    pendingTransactionCompleted:
      "您的充值交易處理已完成，金額為 {{amount}} AT。",
    categories: "選擇類別",
  },
  exchange: {
    exchangeAmount: "轉換金額",
    error: {
      ATPBalanceInsufficient: "餘額不足",
      empty: "轉換金額不能為空",
      emptyExchangeOption: "請先選擇轉換積分",
    },
    selectionTitle: "選擇從哪一種積分轉換",
    exchangedFailed: "請稍後再嘗試",
    noExchangeOption: "你的帳戶暫時沒有轉換選項",
  },
  atpTitle: "聚分俠 ATP",

  earliestAvailableDate: "最早可預訂日：",
  packageOptions: "套餐選項",
  quantityOption: "選擇數量",
  timeOption: "選擇時間",
  orderSummary: "訂單摘要",
  orderTotal: "訂單總額",
  totalPaid: "總金額",
  paidAmount: "支付金額",
  productNo: "產品編號: {{prodNo}}",
  price: "{{at_points}} AT ",
  upTo: "起",
  ages: "({{minAge}} - {{maxAge}} 歲)",
};
