import { t } from "i18next";
import moment from "moment";
import React, { useMemo } from "react";
import { Paper, openWarningNotification } from "src/components";
import { TotalPaidAmount } from "./total-paid-amount";
import { SkusTicketType } from "./skus-ticket-type";
import { EventTimeOptionButton } from "./event-time-option-button";
const { DatePicker } = require("antd");
const inputClass =
  "px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1 rounded-md";

export const KkdayDatePicker = (props: any) => {
  const {
    dateTime,
    eventTime,
    setEventTime,
    skuList,
    setSkuList,
    setDateTime,
    stockSoldOut,
    selectPackage,
    confirmOrderDetails,
    totalAtPoints,
  } = props;

  // Now Time
  let nowDateTime: any = new Date();
  nowDateTime = moment(nowDateTime, "YYYY-MM-DD");

  const { end_date } = selectPackage;
  const { skus } = confirmOrderDetails;

  const handleChange = (date: any, dateString: any) => {
    if (!date) return setDateTime(nowDateTime);
    const formattedDate = moment(dateString).format("YYYY-MM-DD");
    setDateTime(formattedDate);
  };

  const disabledDate = (current: any) => {
    const nowDateTime = current && current.isBefore(moment());
    if (!end_date) return nowDateTime;
    const saleEndDate = moment(end_date).format("YYYY-MM-DD");

    return (
      nowDateTime || (current && current > moment(saleEndDate, "YYYY-MM-DD"))
    );
  };
  const selectEventTime = (e: any, value: any) => {
    // check if event time has already selected
    if (value === eventTime) setEventTime(null);
    setEventTime(value); //Select Event Time
    setSkuList([]); // Reset counts for each sku unit
  };

  const eventTimeOptionList = useMemo(() => {
    //Get Key from time options i.e ticket time
    if (!skus || skus.length === 0) return [];
    if (!dateTime) return [];
    let calendarButtonlist: any = [];
    const sku = skus[0];
    const { calendar } = sku;

    const priceOptions: any =
      (calendar && dateTime && calendar[dateTime.toString()]) || "";

    //Type: Event Time - no option for chosen
    if (!priceOptions["b2b_price"] || !priceOptions["b2c_price"]) {
      openWarningNotification(t("form.soldOut"));
      return [];
    }
    // Check event type
    if (priceOptions && priceOptions["b2b_price"]) {
      const isFulldayKey: any = Object.keys(priceOptions["b2b_price"]).find(
        (item: any) => item === "fullday"
      );
      const isEventTimeKey: any = Object.keys(priceOptions["b2b_price"]).filter(
        (item: any) => item !== "fullday"
      );
      if (isFulldayKey === "fullday") return null; //Type: Non Event

      if (isEventTimeKey && !isEventTimeKey.includes("fullday")) {
        //Get event Time options
        calendarButtonlist = [...calendarButtonlist, ...isEventTimeKey];
      }
    }

    return calendarButtonlist;
  }, [skus, dateTime]);

  return (
    <div className={`mt-[20px]`}>
      <Paper>
        <h5>{t(`kkdayForm.selectDateTime`)}</h5>
        <div className="mt-[10px]">
          <DatePicker
            disabled={confirmOrderDetails && !confirmOrderDetails["skus"]}
            onChange={handleChange}
            picker="date"
            placeholder=""
            defaultPickerValue=""
            className={inputClass}
            disabledDate={disabledDate}
          />
        </div>
        <p className="font-bold text-[12px] text-black mt-[10px]">
          {t(`kkdayForm.selectedDateTime`)}
          <span className="text-at-pink">
            {dateTime ? moment(dateTime).format("DD-MM-YYYY") : ""}
          </span>
        </p>

        {/* Calendar - Event Time / Fullday */}

        {/* Type: Fullday */}
        {!eventTimeOptionList && <></>}

        {/* Type: Event Time */}
        {eventTimeOptionList && (
          <div className="w-full mt-[10px]">
            <h5>{t(`kkdayForm.selectTime`)}</h5>
            <div className="mt-[10px] flex flex-row">
              {dateTime && eventTimeOptionList.length === 0 && (
                <p className="text-[12px] text-slate-400">
                  {t("kkdayForm.soldOut")}
                </p>
              )}

              {eventTimeOptionList.length > 0 &&
                eventTimeOptionList.map((name: any, idx: number) => (
                  <div key={idx}>
                    <EventTimeOptionButton
                      buttonName={name}
                      eventTime={eventTime}
                      selectEventTime={selectEventTime}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}

        {dateTime && confirmOrderDetails && (
          <SkusTicketType
            dateTime={dateTime}
            eventTime={eventTime}
            setEventTime={setEventTime}
            confirmOrderDetails={confirmOrderDetails}
            skuList={skuList}
            setSkuList={setSkuList}
            stockSoldOut={stockSoldOut}
          />
        )}

        {/* Total Sum */}
        <TotalPaidAmount totalAtPoints={totalAtPoints} />
      </Paper>
    </div>
  );
};
