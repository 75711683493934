import React from "react";
const { Carousel } = require("antd");

const carouselStyle = `h-[300px] sm:h-[300px] text-white text-center`;

export const ImgListCarousel = (props: any) => {
  const { logo = "/assets/AT_logo.png" } = props;
  const { img_list = [] } = props.data;

  if (img_list.length === 0) {
    return <img src={logo} alt={"coupon-logo"} className="w-auto h-auto" />;
  }

  return (
    <div>
      <Carousel>
        {img_list.length > 0 &&
          img_list.map((source: any, i: number) => {
            return (
              <div key={i} className="overflow-hidden">
                <div
                  className={`${carouselStyle} w-full bg-cover object-cover`}
                  style={{
                    backgroundImage: source
                      ? `url(${source})`
                      : `url("/assetsAT_logo.png"`,
                  }}
                ></div>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};
