import { t } from "i18next";
import React from "react";
import { ExpamdElementsArrow, Paper } from "src/components";
const { Collapse } = require("antd");

const labelClass = "block text-sm font-medium text-slate-700";
const inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";

export const ContactForm = (props: any) => {
  const { Panel } = Collapse;
  const { bookingField, contactList, setContactList } = props;

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    let newContactObj: any = {
      ...contactList,
      cus_type: "contact",
      [name]: value,
    };
    return setContactList(newContactObj);
  };

  const isNativeFirstNameRequired =
    bookingField["native_first_name"] &&
    bookingField["native_first_name"]["is_contact_used"] &&
    bookingField["native_first_name"]["is_require"] === "True" &&
    bookingField["native_first_name"]["is_contact_used"] === "True";
  const isNativeLastNameRequired =
    bookingField["native_last_name"] &&
    bookingField["native_last_name"]["is_contact_used"] &&
    bookingField["native_last_name"]["is_require"] === "True" &&
    bookingField["native_last_name"]["is_contact_used"] === "True";

  return (
    <div className="mt-[20px] traveller-form">
      {bookingField && (
        <Paper>
          <h5>{t(`kkdayForm.contactInfo`)}</h5>
          <ExpamdElementsArrow>
            <Panel showArrow={true} header={t("kkdayForm.expand")} key={0}>
              <div>
                {isNativeFirstNameRequired && (
                  <div className="">
                    <label className="block mt-5">
                      <span className={labelClass}>
                        {t(`kkdayForm.contactFirstName`)}
                      </span>
                      <input
                        type="text"
                        name={`native_first_name`}
                        className={inputClass}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                )}

                {isNativeLastNameRequired && (
                  <div className="">
                    <label className="block mt-5">
                      <span className={labelClass}>
                        {t(`kkdayForm.contactLastName`)}
                      </span>
                      <input
                        type="text"
                        name={`native_last_name`}
                        className={inputClass}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                )}
              </div>
            </Panel>
          </ExpamdElementsArrow>
        </Paper>
      )}
    </div>
  );
};
