import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { history, useTransactionStore } from "src/stores";
import { TnC } from "./tnc";
import { HowToUse } from "./how-to-use";
import { AvailableOutlets } from "./available-outlets";
import { Info } from "./info";
import {
  openNotificationWithIcon,
  openWarningNotification,
} from "../notification";
import { PaymentOptions } from "./payment-options";
import i18n from "src/i18n";
import { ConfirmToUseButton } from "./confirm-to-use-button";
import { KkdayVoucherSummary } from "./kkday-voucher-summary";
import { KkdayCouponSummary } from "./kkday-coupon-summary";

export const FullTicket = (props: any) => {
  const language = i18n.language;
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const { data, from, type = "", myLinkPoint } = props;
  const { createPaymentOrder } = useTransactionStore();

  const {
    couponTitle,
    couponTitleEn,
    couponIcon = "/assets/AT_logo.png",
    amount,
    atPoints,
    expirationDate,
    earliestAvailableDate,
    availableOutlets = [],
    instructions = [],
    tnc = [],
    remaining,
  } = data;

  const getBtnName = () => {
    switch (from) {
      case "myCoupon": //use-coupon-page
        if (type === "kkday" && remaining) return "button.viewCouponDetail";
        return "ticket.use";
      default:
        return "ticket.get";
    }
  };

  const purchaseCoupon = () => {
    if (!getPaymentOptions || getPaymentOptions.length === 0) {
      return openNotificationWithIcon(
        "info",
        t("messages.comingSoon"),
        t("messages.comingSoon")
      );
    }
    return setOpenPayment(true);
  };

  const getPaymentOptions = useMemo(() => {
    const { amount, atPoints } = data;
    let paymentOptions: any = [];
    if (amount) {
      // TODO add card - paymentOptions = [...paymentOptions, "card"];
    }
    if (atPoints) paymentOptions = [...paymentOptions, "at_points"];
    if (myLinkPoint > -1) paymentOptions = [...paymentOptions, "myLink_points"];

    return paymentOptions;
  }, [data, myLinkPoint]);

  const title = useMemo(() => {
    if (language === "en") return couponTitleEn || couponTitle;
    return couponTitle;
  }, [language, couponTitle, couponTitleEn]);

  // Create Kkday Payment Order
  const createKkdayOrder = async (data: any, from: any) => {
    const payload = {
      amount: data["at_points"],
      currency: "AT",
      type: "purchase_coupon",
      method: "kkday",
    };
    const order = await createPaymentOrder(payload);
    if (!order)
      return openWarningNotification(t("messages.failedToCreatePaymentOrder"));
    return history.push("/kkday-coupon-detail-form", {
      coupon: data,
      from,
      paymentOrderId: order["order_id"],
    });
  };

  const handleActionButton = async (from: string) => {
    // Redirect to kkday detail form
    if (type === "kkday") {
      // Create Kkday Order
      return await createKkdayOrder(data, from);
    }

    switch (from) {
      case "myCoupon": //use-coupon-page
        history.push("/use-coupon", { coupon: data });
        break;
      default:
        purchaseCoupon();
        break;
    }
  };

  const allowRedeemKkdayConpon = useMemo(() => {
    //My Coupon Page
    // if (!from || !type) return;
    if (from === "myCoupon" && type === "kkday") return true;
    return false;
  }, [from, type]);

  const redeemedKkdayCoupon = useMemo(() => {
    //KKDAY Redeemption
    //remaining: 0 [Used], remaining: 1: [Not Used]
    if (remaining > 0) return false;
    return true;
  }, [remaining]);

  return (
    <div className="relative w-full bg-white flex flex-col px-5 py-5 items-center rounded-2xl border-[1px]">
      {!allowRedeemKkdayConpon && (
        <>
          <div className="w-14 h-14 rounded-full overflow-hidden">
            <img
              src={couponIcon}
              alt={couponTitle}
              className=" w-full h-full object-contain"
            />
          </div>
          {/* Conpon Info Details */}
          <Info
            couponTitle={title}
            amount={amount}
            atPoints={atPoints}
            expirationDate={expirationDate}
            earliestAvailableDate={earliestAvailableDate}
          />
          <div className="my-5 h-[1px] block bg-slate-200 w-[90%]" />
        </>
      )}

      {/* KKDAY Voucher Download */}
      {allowRedeemKkdayConpon && (
        <KkdayVoucherSummary
          data={data}
          redeemedKkdayCoupon={redeemedKkdayCoupon}
        />
      )}

      {/* Place to use */}
      <AvailableOutlets data={availableOutlets} />

      <div className="my-5 h-[1px] block bg-slate-200 w-[90%]" />

      {/* KKDAY Coupon Summary With How to use and TnC */}
      {type === "kkday" && <KkdayCouponSummary data={data} />}

      {/* How to use */}
      {type !== "kkday" && (
        <>
          <div className="my-5 h-[1px] block bg-slate-200 w-[90%]" />
          <HowToUse data={instructions} type={type} />
          <div className="my-5 h-[1px] block bg-slate-200 w-[90%]" />
          {/* T & C */}
          <TnC data={tnc} type={type} />
        </>
      )}

      {/* Get button - My Coupon (Use Coupon) */}
      {allowRedeemKkdayConpon ? (
        <ConfirmToUseButton data={data} />
      ) : (
        <div
          className="fixed top-[90%] px-8 w-screen"
          onClick={() => handleActionButton(from)}
        >
          <button className="full py-3 text-xs">{t(getBtnName())}</button>
        </div>
      )}

      {/* Bottom sheet - Show payment options */}
      <PaymentOptions
        open={openPayment}
        setOpen={setOpenPayment}
        options={getPaymentOptions}
        coupon={data}
        myLinkPoint={myLinkPoint}
      />

      {/* Left circle */}
      <div className="w-5 h-5 bg-[#fafafa] rounded-full absolute top-[100px] left-[-10px] overflow-hidden" />

      {/* Right circle */}
      <div className="w-5 h-5 bg-[#fafafa] rounded-full absolute top-[100px] right-[-10px] overflow-hidden" />
    </div>
  );
};
