import React from "react";
import { TravelerInfoForm } from "./form/travelerInfo-form";

export const TravelerInformation = (props: any) => {
  return (
    <div>
      <TravelerInfoForm {...props} />
    </div>
  );
};
