import { t } from "i18next";
import moment from "moment";
import React from "react";
import { Paper } from "../paper";
import { SeperateLine } from "../seperate-line";

export const VoucherSummary = (props: any) => {
  const {
    allowLabel = true,
    atPoints,
    data = {},
    startDate = "",
    endDate = "",
    eventTime = "",
    fromPage = "",
    formattedSkuNameList = [],
  } = props;

  const { coupon_id, pkg_name, couponIcon = "/assets/AT_logo.png" } = data;

  const CouponText = (
    text: string,
    atPoints: string,
    highlightText: boolean
  ) => {
    return (
      <div className="w-full flex flex-row justify-between items-center">
        <p>{t(text)}</p>
        <p
          className={`font-bold ${
            highlightText
              ? "text-at-pink text-[24px] font-[800] !tracking-[-1px]"
              : "text-sm text-black"
          }`}
        >{`${atPoints} AT`}</p>
      </div>
    );
  };

  return (
    <Paper fromPage={fromPage}>
      {allowLabel && <h5>{t(`orderSummary`)}</h5>}
      {/* Product Image */}
      <div className="mt-4 overflow-hidden">
        <img
          src={couponIcon}
          alt={"coupon-logo"}
          className="w-full bg-cover object-cover rounded-md"
        />
      </div>

      {/* Product Location */}
      <div className="mt-4 flex flex-1 justify-between items-center mb-3">
        <p>{t("ticket.hk")}</p>
        <p>{t("productNo", { prodNo: coupon_id })}</p>
      </div>

      {/* Product Name */}
      <p className="font-bold text-[16px] text-black">{pkg_name}</p>

      {/* Product Image */}
      {startDate && (
        <div className="mt-[10px] flex flex-row items-center overflow-hidden">
          <img
            src={`/assets/events_and_exhibitions.png`}
            alt={`event-exhibitions-icon`}
            className="w-[15px] h-[15px]"
          />

          {/* Selected Date Time (KKDAY Form Page)*/}
          {fromPage === "kkday-coupon-detail-form" && (
            <p className="ml-2 mt-[3px]">
              {moment(startDate).format("MMMM d, YYYY")}
            </p>
          )}

          {/* Validated Coupon Date Time (Conpon Purchased Page) */}
          {(fromPage === "myCoupon" || fromPage === "coupon-purchased") &&
            startDate &&
            endDate && (
              <p className="ml-2 mt-[3px]">
                {`${moment(startDate).format("MMMM d, YYYY")} - ${moment(
                  endDate
                ).format("MMMM d, YYYY")} ${eventTime ? `,${eventTime}` : ""}`}
              </p>
            )}
        </div>
      )}

      {formattedSkuNameList.length > 0 && (
        <div className="mt-[10px] flex flex-row items-center overflow-hidden">
          <img
            src={`/assets/account.png`}
            alt={`account-icon`}
            className="w-[15px] h-[15px]"
          />
          {formattedSkuNameList.map((spec: any, i: number) => {
            return (
              <p className="ml-2 mt-[3px]" key={i}>
                {t(`kkdayForm.${spec.spec_type}`)}
                {`x ${spec.qty}`}
              </p>
            );
          })}
        </div>
      )}

      {fromPage === "myCoupon" ||
        (fromPage === "coupon-purchased" && ( //Use Coupon
          <div className="mt-[20px]">
            {CouponText("totalPaid", atPoints, false)}
            <SeperateLine />
            <div className="mt-[10px]">
              {CouponText("paidAmount", atPoints, true)}
            </div>
          </div>
        ))}
    </Paper>
  );
};
